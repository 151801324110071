import React from "react";
import { Link, useNavigate } from "react-router-dom";
import s from "../../styles/PersonalAccount/PersonalAccountPage.module.css";
import {
  BackButton,
  useHapticFeedback,
} from "@vkruglikov/react-telegram-web-app";

const PersonalAccountPage = () => {
  const navigate = useNavigate();
  const [impactOccurred] = useHapticFeedback();

  window.Telegram.WebApp.disableClosingConfirmation();

  return (
    <>
      <BackButton onClick={() => navigate("/")} />
      <div className={s.container}>
        <div className={s.title}>🪪 Личный кабинет</div>
        <div className={s.buttons_group}>
          <Link
            to="/my_ads"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            📖 Мои объявления
          </Link>
          <Link
            to="/settings"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            ⚙️ Настройки
          </Link>
        </div>
      </div>
    </>
  );
};

export default PersonalAccountPage;
