import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BackButton,
  MainButton,
  useHapticFeedback,
  useThemeParams,
  useCloudStorage,
  useShowPopup,
  useInitData,
} from "@vkruglikov/react-telegram-web-app";
import s from "../../styles/Registration/SetCity.module.css";
import axios from "axios";
import { Loader } from "../../components/Loader";
import { useProfile } from "../../contexts/ProfileContext";

const SetCity = () => {
  const { fetchProfile } = useProfile();
  const navigate = useNavigate();
  const [, notificationOccurred] = useHapticFeedback();
  const [, themeParams] = useThemeParams();
  const storage = useCloudStorage();
  const showPopup = useShowPopup();
  const [city, setCity] = useState("");
  const [, InitData] = useInitData();
  const [isLoading, setIsLoading] = useState(false);

  window.Telegram.WebApp.enableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const usersApiPath = `${BASE_URL}/api/users`;

  const handleCompleteRegistration = async () => {
    if (city) {
      let name = await storage.getItem("name");
      let phoneNumber = await storage.getItem("phoneNumber");
      try {
        setIsLoading(true);
        await axios.post(usersApiPath + "/create_profile", {
          name,
          phone_number: phoneNumber,
          city,
          init_data: InitData,
        });
        setIsLoading(false);
        notificationOccurred("success");
        await showPopup({
          message: "✅ Регистрация завершена!",
        });
        await fetchProfile();
        navigate("/", { replace: true });
      } catch (error) {
        console.log(error);
        notificationOccurred("error");
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      }
    } else {
      notificationOccurred("warning");
      await showPopup({
        message: "⚠️ Вы не ввели город.",
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <p className={s.text}>✏️ Введите название города.</p>
        <input
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className={s.input}
        />
      </div>
      {city && (
        <MainButton
          text={"ЗАВЕРШИТЬ РЕГИСТРАЦИЮ"}
          color={themeParams.hint_color}
          onClick={handleCompleteRegistration}
        />
      )}
    </>
  );
};

export default SetCity;
