const areAllFieldsFilled = (sections, state, selectedFiles) => {
  // Специфическая проверка для гаража и парковочного места
  const GARAGE = "0";
  const GARAGE_BOX = "1";
  const PARKING_SPACE = "2";

  const objectType = state.object_type;
  if (objectType === GARAGE || objectType === GARAGE_BOX) {
    if (!state.garage_type || state.garage_type === null || state.garage_type === "") {
      return false;
    }
  } else if (objectType === PARKING_SPACE) {
    if (!state.type_of_parking_space || state.type_of_parking_space === null || state.type_of_parking_space === "") {
      return false;
    }
  }

  // Список полей, которые нужно игнорировать в основной проверке
  const fieldsToIgnore = ["type_of_parking_space", "garage_type"];

  // Проверка полей в каждой секции
  for (const section of Object.values(sections)) {
    for (const [fieldKey, fieldValue] of Object.entries(section.fields)) {
      if (fieldsToIgnore.includes(fieldKey)) {
        continue; // Игнорируем проверку для этих полей
      }

      if (typeof fieldValue === "object" && fieldValue !== null) {
        if (fieldValue.type === "MULTISELECT") {
          // Проверяем, что хотя бы одно из полей заполнено
          if (!state[fieldKey] || state[fieldKey].length === 0) {
            if (fieldValue.other === true && (state[fieldKey + "_other"] === null || state[fieldKey + "_other"] === "")) {
              return false; // Если и MULTISELECT и other не заполнены, возвращаем false
            }
          }
        } else if (fieldValue.type === "SELECT") {
          // Проверяем, что хотя бы одно из полей заполнено
          if (!state[fieldKey] || state[fieldKey] === null || state[fieldKey] === "") {
            if (fieldValue.other === true && (state[fieldKey + "_other"] === null || state[fieldKey + "_other"] === "")) {
              return false; // Если и SELECT и other не заполнены, возвращаем false
            }
          }
        } else {
          if (!state[fieldKey] || state[fieldKey] === null || state[fieldKey] === "") {
            return false;
          }
        }
      } else {
        if (!state[fieldKey] || state[fieldKey] === null || state[fieldKey] === "") {
          return false;
        }
      }
    }
  }

  // Проверка на наличие загруженных файлов (если это требуется)
  if (selectedFiles.length === 0) return false;

  return true;
};

export default areAllFieldsFilled;
