import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BackButton,
  MainButton,
  useHapticFeedback,
  useThemeParams,
  useCloudStorage,
  useShowPopup,
} from "@vkruglikov/react-telegram-web-app";
import s from "../../styles/Registration/SetName.module.css";

const SetName = () => {
  const navigate = useNavigate();
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const [, themeParams] = useThemeParams();
  const storage = useCloudStorage();
  const showPopup = useShowPopup();
  const [name, setName] = useState("");

  window.Telegram.WebApp.enableClosingConfirmation();

  const handleNext = async () => {
    if (name && name.length <= 128) {
      impactOccurred("light");
      await storage.setItem("name", name);
      navigate("/reg/set_phone_number");
    } else {
      notificationOccurred("warning");
      await showPopup({
        message:
          "⚠️ Вы не ввели имя или превысили допустимую длину в 128 символов.",
      });
    }
  };

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <p className={s.text}>✏️ Введите имя.</p>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={s.input}
        />
      </div>
      {name && (
        <MainButton
          text={"ДАЛЕЕ"}
          color={themeParams.hint_color}
          onClick={handleNext}
        />
      )}
    </>
  );
};

export default SetName;
