import React from "react";
import FieldContainer from "./FieldContainer";

const SelectField = ({ field_key, field_value, state, onChange }) => (
  <FieldContainer title={field_value.title}>
    <div className="select_field">
      <select
        name={field_key}
        value={state[field_key] || "default_option"}
        onChange={onChange}
      >
        <option value="default_option">Выберите</option>
        {field_value.values.map((value, index) => (
          <option key={index} value={index}>
            {value}
          </option>
        ))}
      </select>
    </div>
  </FieldContainer>
);

export default SelectField;
