import React from "react";
import "../../styles/FieldContainer.css";

const FieldContainer = ({
  title,
  fieldContainerClassName = "field_container",
  fieldNameClassName = "field_name",
  children,
}) => (
  <div className={fieldContainerClassName}>
    <div className={fieldNameClassName}>
      <b>
        <i>{title}:</i>
      </b>
    </div>
    {children}
  </div>
);

export default FieldContainer;
