/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import s from "../styles/PaymentPage.module.css";
import {
  BackButton,
  useHapticFeedback,
  useShowPopup,
  useWebApp,
  useInitData,
} from "@vkruglikov/react-telegram-web-app";

const PaymentPage = () => {
  const { adId } = useParams();
  const navigate = useNavigate();
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const [tariffPlans, setTariffPlans] = useState([]);
  const [selectedTariffPlan, setSelectedTariffPlan] = useState("");
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");
  const [, initData] = useInitData();
  const showPopup = useShowPopup();
  const WebApp = useWebApp();

  window.Telegram.WebApp.enableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const yookassaApiPath = `${BASE_URL}/yookassa`;
  const tariffPlansApiPath = `${BASE_URL}/api/tariff_plans`;

  useEffect(() => {
    const fetchTariffs = async () => {
      try {
        const response = await axios.get(tariffPlansApiPath + `?ad_id=${adId}`);
        setTariffPlans(response.data);

        // Устанавливаем первый тариф как выбранный по умолчанию
        if (response.data.length > 0) {
          const defaultTariff = response.data[0];
          setSelectedTariffPlan(defaultTariff.name);
          setAmount(defaultTariff.price);
        }
      } catch (error) {
        console.error("Ошибка при загрузке тарифов:", error);
        showPopup({
          message: "❗️ Не удалось загрузить тарифы. Попробуйте позже.",
        });
      }
    };
    fetchTariffs();
  }, [BASE_URL, adId, showPopup, tariffPlansApiPath]);

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      notificationOccurred("warning");
      await showPopup({
        message: "⚠️ Некорректный e-mail. Попробуйте еще раз.",
      });
      return;
    }

    try {
      const response = await axios.post(yookassaApiPath + "/create_payment", {
        ad_id: adId,
        email,
        tariff_plan: selectedTariffPlan,
        init_data: initData,
      });
      notificationOccurred("success");
      setPaymentUrl(response.data.payment_url);
    } catch (error) {
      console.error(error);
      notificationOccurred("error");
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    }
  };

  const handleProceedToPayment = () => {
    impactOccurred("light");
    WebApp.openLink(paymentUrl);
    WebApp.close();
  };

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.wrapper}>
        <div className={s.container}>
          <div className={s.title}>Оплата за размещение объявления</div>
          <div className={s.description}>
            Оплачивая вы соглашаетесь с условиями{" "}
            <a
              href="#"
              onClick={() =>
                WebApp.openLink(
                  "https://docs.google.com/document/d/1gL7qyqKUyiHep6a_RJtzamGx0XIZfmTxV6vJ75-kJ6I/edit?usp=sharing"
                )
              }
              className={s.link}
            >
              оферты
            </a>{" "}
            и{" "}
            <a
              href="#"
              onClick={() =>
                WebApp.openLink(
                  "https://docs.google.com/document/d/1O28Buo7lqgIgkLQoagSjqgKzWeR9_lWDPIPIRgy76Kk/edit?usp=sharing"
                )
              }
              className={s.link}
            >
              политикой конфиденциальности
            </a>
            .
          </div>
          {!paymentUrl ? (
            <form onSubmit={handleSubmit} className={s.form}>
              <div className={s.formGroup}>
                <label htmlFor="tariff" className={s.label}>
                  Тарифный план:
                </label>
                <select
                  id="tariff"
                  className={s.select}
                  value={selectedTariffPlan}
                  onChange={(e) => {
                    const selected = e.target.value;
                    setSelectedTariffPlan(selected);

                    const selectedTariffObj = tariffPlans.find(
                      (tariff) => tariff.name === selected
                    );
                    if (selectedTariffObj) {
                      setAmount(selectedTariffObj.price);
                    }
                  }}
                >
                  {tariffPlans.map((tariff) => (
                    <option key={tariff.name} value={tariff.name}>
                      {tariff.verbose_name} - {tariff.price} руб.
                    </option>
                  ))}
                </select>
              </div>
              <div className={s.formGroup}>
                <label htmlFor="amount" className={s.label}>
                  Сумма:
                </label>
                <input
                  type="text"
                  id="amount"
                  className={s.input}
                  value={amount}
                  readOnly
                />
              </div>
              <div className={s.formGroup}>
                <label htmlFor="email" className={s.label}>
                  E-mail:
                </label>
                <input
                  type="text"
                  id="email"
                  className={s.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Введите свой e-mail"
                />
              </div>
              <button type="submit" className="button">
                Подтвердить
              </button>
            </form>
          ) : (
            <div className={s.success}>
              <p>✅ Готово! Нажмите на кнопку для оплаты:</p>
              <button onClick={handleProceedToPayment} className="button">
                Перейти к оплате
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentPage;
