import React, { useState } from "react";
import {
  BackButton,
  MainButton,
  useHapticFeedback,
  useThemeParams,
  useShowPopup,
  useInitData,
} from "@vkruglikov/react-telegram-web-app";
import axios from "axios";
import { Loader } from "../../components/Loader";
import s from "../../styles/Registration/SetCity.module.css";
import { useNavigate } from "react-router-dom";

const ChangeCity = () => {
  const [city, setCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [, notificationOccurred] = useHapticFeedback();
  const showPopup = useShowPopup();
  const [, InitData] = useInitData();
  const [, themeParams] = useThemeParams();

  window.Telegram.WebApp.enableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const usersApiPath = `${BASE_URL}/api/users`;

  const handleSaveChanges = async () => {
    if (city) {
      try {
        setIsLoading(true);
        await axios.patch(usersApiPath + "/update_profile", {
          city,
          init_data: InitData,
        });
        setIsLoading(false);
        notificationOccurred("success");
        await showPopup({
          message: "✅ Город изменен!",
        });
        navigate(-1);
      } catch (error) {
        console.log(error);
        notificationOccurred("error");
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      }
    } else {
      notificationOccurred("warning");
      await showPopup({
        message: "⚠️ Вы не ввели город.",
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <p className={s.text}>✏️ Введите название города.</p>
        <input
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className={s.input}
        />
      </div>
      {city && (
        <MainButton
          text={"СОХРАНИТЬ ИЗМЕНЕНИЯ"}
          color={themeParams.hint_color}
          onClick={handleSaveChanges}
        />
      )}
    </>
  );
};

export default ChangeCity;
