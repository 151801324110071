import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import s from "../../styles/Moderation/ModerationPage.module.css";
import {
  BackButton,
  useHapticFeedback,
  useShowPopup,
  useInitData,
} from "@vkruglikov/react-telegram-web-app";
import { Loader } from "../../components/Loader";
import axios from "axios";

const ModerationPage = () => {
  const navigate = useNavigate();
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const showPopup = useShowPopup();
  const [unverifiedAdsCount, setUnverifiedAdsCount] = useState(0);
  const [unverifiedProfilesCount, setUnverifiedProfilesCount] = useState(0);
  const [paymentIsEnable, setPaymentIsEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, InitData] = useInitData();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const moderationApiPath = `${BASE_URL}/api/moderation`;
  const settingsApiPath = `${BASE_URL}/api/settings`;

  window.Telegram.WebApp.disableClosingConfirmation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          moderationApiPath + "/get_moderation_data",
          {
            init_data: InitData,
          }
        );
        setUnverifiedProfilesCount(response.data.unverified_profiles_count);
        setUnverifiedAdsCount(response.data.unverified_ads_count);
        setPaymentIsEnable(response.data.payment_is_enable);
      } catch (error) {
        console.error(error);
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [InitData, moderationApiPath, showPopup]);

  const handleTogglePaymentSetting = async () => {
    try {
      const response = await axios.patch(
        settingsApiPath + "/change_status/payment",
        {
          init_data: InitData,
        }
      );
      notificationOccurred("success");
      setPaymentIsEnable(response.data);
    } catch (error) {
      console.log(error);
      notificationOccurred("error");
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate("/")} />
      <div className={s.container}>
        <div className={s.title}>🔰 Модерация</div>
        <div className={s.buttons_group}>
          <Link
            to="/unverified_ads"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            📖 Объявления на проверку | {unverifiedAdsCount} шт.
          </Link>
          <Link
            to="/unverified_profiles"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            🪪 Профили на проверку | {unverifiedProfilesCount} шт.
          </Link>
          <button className={s.button} onClick={handleTogglePaymentSetting}>
            {paymentIsEnable ? "✅ Оплата включена" : "⬜️ Оплата выключена"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ModerationPage;
