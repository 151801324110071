import React from "react";
import { Link } from "react-router-dom";
import s from "../../styles/ModalStyles.module.css";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

function BalanceModal({ isOpen, onClose, title, currentBalance }) {
  const [impactOccurred] = useHapticFeedback();
  if (!isOpen) return null;

  return (
    <div className={s.modal}>
      <div className={s.modalContent}>
        <div className={s.title}>{title}</div>
        {currentBalance && (
          <div className={s.text}>
            <b>Текущий баланс:</b> {currentBalance} ₽
          </div>
        )}
        <div className={s.buttons_group}>
          <Link
            to="/top_up_balance"
            className="button"
            onClick={() => impactOccurred("light")}
          >
            Пополнить баланс
          </Link>
          <button
            className="button"
            onClick={() => {
              impactOccurred("light");
              onClose();
            }}
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
}

export default BalanceModal;
