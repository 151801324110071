import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import s from "../../styles/SettingsPage.module.css";
import {
  BackButton,
  useHapticFeedback,
  useInitData,
  useShowPopup,
} from "@vkruglikov/react-telegram-web-app";
import DeleteModal from "../../components/Modals/DeleteModal";
import axios from "axios";
import { useProfile } from "../../contexts/ProfileContext";

const SettingsPage = () => {
  const { fetchProfile } = useProfile();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const [, InitData] = useInitData();
  const showPopup = useShowPopup();

  window.Telegram.WebApp.disableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const usersApiPath = `${BASE_URL}/api/users`;

  const handleDelete = async () => {
    try {
      await axios.delete(usersApiPath + "/delete_profile", {
        data: {
          init_data: InitData,
        },
      });
      setIsDeleteModalOpen(false);
      notificationOccurred("success");
      showPopup({
        message: "✅ Профиль удален.",
      });
      await fetchProfile();
    } catch (error) {
      console.error(error);
      notificationOccurred("error");
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    }
  };

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <div className={s.title}>⚙️ Настройки</div>
        <div className={s.buttons_group}>
          <Link
            to="/change_phone_number"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            📞 Сменить номер телефона
          </Link>
          <Link
            to="/change_city"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            🌆 Сменить город
          </Link>
          <button
            className={s.button}
            onClick={() => {
              impactOccurred("light");
              setIsDeleteModalOpen(true);
            }}
          >
            ❌ Удалить профиль
          </button>
        </div>
      </div>

      <DeleteModal
        message={"❔ Вы уверены, что хотите удалить профиль?"}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default SettingsPage;
