const initializeState = (formFields) => {
    let initialState = {};
    for (let section of Object.values(formFields.sections)) {
      for (let [field_key, field_value] of Object.entries(section.fields)) {
        if (typeof field_value === "object" && field_value !== null) {
          if (field_value.type === "MULTISELECT") {
            initialState[field_key] = [];
            if (field_value.other === true) {
              initialState[field_key + "_other"] = null;
            }
          } else if (field_value.type === "SELECT") {
            initialState[field_key] = null;
            if (field_value.other === true) {
              initialState[field_key + "_other"] = null;
            }
          } else {
            initialState[field_key] = null;
          }
        } else {
          initialState[field_key] = null;
        }
      }
    }
    return initialState;
  }

  export default initializeState;