import React, { useState } from "react";
import InputMask from "react-input-mask";
import {
  BackButton,
  MainButton,
  useHapticFeedback,
  useThemeParams,
  useShowPopup,
  useInitData,
} from "@vkruglikov/react-telegram-web-app";
import axios from "axios";
import { Loader } from "../../components/Loader";
import s from "../../styles/Registration/SetPhoneNumber.module.css";
import { useNavigate } from "react-router-dom";

const ChangePhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [, notificationOccurred] = useHapticFeedback();
  const showPopup = useShowPopup();
  const [, InitData] = useInitData();
  const [, themeParams] = useThemeParams();

  window.Telegram.WebApp.enableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const usersApiPath = `${BASE_URL}/api/users`;

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
    return phoneRegex.test(number);
  };

  const cleanPhoneNumber = (number) => {
    return number.replace(/\D/g, "").replace(/^7/, "");
  };

  const handleSaveChanges = async () => {
    if (validatePhoneNumber(phoneNumber)) {
      try {
        setIsLoading(true);
        await axios.patch(usersApiPath + "/update_profile", {
          phone_number: cleanPhoneNumber(phoneNumber),
          init_data: InitData,
        });
        setIsLoading(false);
        notificationOccurred("success");
        await showPopup({
          message: "✅ Номер телефона изменен!",
        });
        navigate(-1);
      } catch (error) {
        console.log(error);
        notificationOccurred("error");
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      }
    } else {
      notificationOccurred("warning");
      await showPopup({
        message: "⚠️ Вы не ввели номер телефона или неверный формат.",
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <p className={s.text}>
          ✏️ Введите номер телефона в формате <code>+7 (XXX) XXX-XX-XX</code>.
        </p>
        <InputMask
          mask="+7 (999) 999-99-99"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className={s.input}
          placeholder="+7 (___) ___-__-__"
          maskChar="_"
        />
      </div>
      {phoneNumber && (
        <MainButton
          text={"СОХРАНИТЬ ИЗМЕНЕНИЯ"}
          color={themeParams.hint_color}
          onClick={handleSaveChanges}
        />
      )}
    </>
  );
};

export default ChangePhoneNumber;
