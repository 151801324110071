import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Link,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import s from "../styles/AdsList.module.css";
import {
  BackButton,
  useInitData,
  useShowPopup,
  useHapticFeedback,
  useCloudStorage,
} from "@vkruglikov/react-telegram-web-app";
import { Loader } from "../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faMagnifyingGlass,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import AdDetails from "../components/AdDetails";

function AdsList() {
  const [searchParams] = useSearchParams();
  const adCategory = searchParams.get("ad_category");
  const categoryLvl0 = searchParams.get("category_lvl_0");
  const categoryLvl1 = searchParams.get("category_lvl_1");
  const categoryLvl2 = searchParams.get("category_lvl_2");
  const operationType = searchParams.get("operation_type");
  const queryParams =
    `ad_category=${adCategory}&category_lvl_0=${categoryLvl0}&category_lvl_1=${categoryLvl1}&category_lvl_2=${categoryLvl2}` +
    (operationType ? `&operation_type=${operationType}` : "");

  const [, InitData] = useInitData();
  const showPopup = useShowPopup();
  const [impactOccurred] = useHapticFeedback();
  const storage = useCloudStorage();

  const location = useLocation();
  const navigate = useNavigate();
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const adsPerPage = 2; // Уменьшил количество объявлений на странице для тестирования
  const [myAds, setMyAds] = useState(false);
  const [adsByRating, setAdsByRating] = useState(false);
  const [verifiedAds, setVerifiedAds] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState({});

  window.Telegram.WebApp.disableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const adsApiPath = `${BASE_URL}/api/ads`;
  const photosApiPath = `${BASE_URL}/api/photos`;

  const adAboutPath = "/ads/{ad_id}";
  const adsFilterPath = "/ads/filter?" + queryParams;
  const adFormPath = "/form?action=create&" + queryParams;

  const lightImpactOccurred = () => {
    impactOccurred("light");
  };

  useEffect(() => {
    const fetchData = async () => {
      const searchAreaData = JSON.parse(
        await storage.getItem("searchAreaData")
      );

      const config = {
        search_by: searchAreaData.searchBy,
        address: searchAreaData.address,
        ad_category: adCategory,
        category_lvl_0: categoryLvl0,
        category_lvl_1: categoryLvl1,
        category_lvl_2: categoryLvl2,
        extra: location.state?.filters || {},
        my_ads: myAds,
        ads_by_rating: adsByRating,
        verified_ads: verifiedAds,
        init_data: InitData,
      };
      if (operationType) {
        config["operation_type"] = operationType;
      }
      if (searchAreaData.searchBy === "region") {
        if (searchAreaData.region !== null)
          config["region"] = searchAreaData.region;
      } else if (searchAreaData.searchBy === "city") {
        if (searchAreaData.city !== null) config["city"] = searchAreaData.city;
      } else {
        config["radius"] = searchAreaData.radius;
        if (searchAreaData.geoLat !== null && searchAreaData.geoLon !== null) {
          config["geo_lat"] = searchAreaData.geoLat;
          config["geo_lon"] = searchAreaData.geoLon;
        }
      }
      setIsLoading(true); // Начало загрузки
      try {
        const response = await axios.post(
          `${adsApiPath}/get_ads_by_filters`,
          config
        );
        const data = response.data;
        setAds(data.ads);
        setFormFields(data.form_fields);
      } catch (error) {
        console.error(error);
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      } finally {
        setIsLoading(false); // Конец загрузки
      }
    };
    fetchData();
  }, [
    location,
    adCategory,
    categoryLvl0,
    categoryLvl1,
    categoryLvl2,
    operationType,
    myAds,
    adsByRating,
    verifiedAds,
    adsApiPath,
    InitData,
    showPopup,
    storage,
  ]);

  const handleNextPage = () => {
    if (page * adsPerPage < ads.length) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Расчет индексов для текущей страницы
  const startIndex = (page - 1) * adsPerPage;
  const endIndex = page * adsPerPage;
  const paginatedAds = ads.slice(startIndex, endIndex);

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate("/", { replace: true })} />
      <div className={s.wrapper}>
        <header className={s.header}>
          <div className={s.header__container}>
            <div className={s.header__filter_and_sort}>
              <Link
                to="/input_search_area"
                className="button"
                onClick={lightImpactOccurred}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} /> ДРУГИЕ ОБЪЯВЛЕНИЯ
              </Link>
              <Link
                to={adsFilterPath}
                className="button"
                onClick={lightImpactOccurred}
                state={location.state}
              >
                <FontAwesomeIcon icon={faFilter} /> ФИЛЬТР
              </Link>
              <Link
                to={adFormPath}
                className="button"
                onClick={lightImpactOccurred}
              >
                <FontAwesomeIcon icon={faPlus} /> СОЗДАТЬ
              </Link>
              <div className={s.sort_by}>
                <label className={s.header__sort_label}>
                  <input
                    type="checkbox"
                    checked={myAds}
                    onChange={(e) => setMyAds(e.target.checked)}
                    className={s.header__sort_checkbox}
                  />
                  <span className={s.custom_checkbox}></span>
                  Только мои
                </label>
                <label className={s.header__sort_label}>
                  <input
                    type="checkbox"
                    checked={adsByRating}
                    onChange={(e) => setAdsByRating(e.target.checked)}
                    className={s.header__sort_checkbox}
                  />
                  <span className={s.custom_checkbox}></span>
                  По рейтингу
                </label>
                <label className={s.header__sort_label}>
                  <input
                    type="checkbox"
                    checked={verifiedAds}
                    onChange={(e) => setVerifiedAds(e.target.checked)}
                    className={s.header__sort_checkbox}
                  />
                  <span className={s.custom_checkbox}></span>
                  Проверенные
                </label>
              </div>
            </div>
          </div>
        </header>
        <main className={s.main}>
          <div className={s.main__container}>
            <div className={s.ad_cards}>
              {paginatedAds.map((ad) => (
                <div key={ad.ad_id} className={s.ad_card}>
                  <Link
                    to={adAboutPath.replace("{ad_id}", ad.ad_id)}
                    onClick={lightImpactOccurred}
                  >
                    <div className={s.ad_card__stats}>
                      <div className={s.stats__is_verified}>
                        {!ad.need_verification && ad.is_verified
                          ? "✅ Объявление проверено"
                          : "❌ Объявление не проверено"}
                      </div>
                      <div
                        className={s.stats__avg_rating}
                      >{`⭐️ Рейтинг | ${ad.avg_rating}/5`}</div>
                      <div
                        className={s.stats__views_count}
                      >{`👁‍🗨 Просмотры | ${ad.views_count} шт.`}</div>
                    </div>
                    <div className={s.ad_card__photo}>
                      <img
                        src={`${photosApiPath}/${ad.photos[0]}`}
                        alt={`preview for ad#${ad.ad_id}`}
                      />
                    </div>
                    {ad && formFields && (
                      <AdDetails
                        ad={ad}
                        adFormFields={
                          ad.operation_type
                            ? formFields[
                                `${ad.ad_category}_${ad.operation_type}`
                              ]
                            : formFields[ad.ad_category]
                        }
                      />
                    )}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </main>
        <footer className={s.footer}>
          <div className={s.footer__container}>
            <div className={s.pagination}>
              <button
                className="button"
                onClick={handlePreviousPage}
                disabled={page === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="button"
                onClick={handleNextPage}
                disabled={endIndex >= ads.length}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default AdsList;
