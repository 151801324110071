import { useProfile } from "../contexts/ProfileContext";
import { Navigate } from "react-router-dom";
import { Loader } from "../components/Loader";

export function ProtectedRoute({ children }) {
  const { hasProfile, isLoading, isProfileChecked } = useProfile();

  if (isLoading || !isProfileChecked) {
    return <Loader />;
  }

  if (!hasProfile) {
    return <Navigate to="/no_profile" />;
  }

  return children;
}
