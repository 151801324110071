import React from "react";
import FieldContainer from "./FieldContainer";

const TextareaField = ({ field_key, field_value, state, onChange }) => (
  <FieldContainer title={field_value}>
    <div className="textarea_field">
      <textarea
        name={field_key}
        onChange={onChange}
        placeholder="Введите текст"
        value={state[field_key] || ""}
      />
    </div>
  </FieldContainer>
);

export default TextareaField;
