import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import s from "../../styles/Moderation/UnverifiedAdsList.module.css";
import {
  BackButton,
  useInitData,
  useShowPopup,
  useHapticFeedback,
} from "@vkruglikov/react-telegram-web-app";
import { Loader } from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import AdDetails from "../../components/AdDetails";

function UnverifiedAdsList() {
  const [, InitData] = useInitData();
  const showPopup = useShowPopup();
  const [impactOccurred] = useHapticFeedback();

  const location = useLocation();
  const navigate = useNavigate();
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const adsPerPage = 2;
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState({});

  window.Telegram.WebApp.disableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const moderationApiPath = `${BASE_URL}/api/moderation`;
  const photosApiPath = `${BASE_URL}/api/photos`;

  let adAboutPath = `/ads/{ad_id}`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          moderationApiPath + "/ads/get_unverified",
          {
            init_data: InitData,
          }
        );
        const data = response.data;
        setAds(data.ads);
        setFormFields(data.form_fields);
      } catch (error) {
        console.error(error);
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [location, InitData, showPopup, moderationApiPath]);

  const handleNextPage = () => {
    if (page * adsPerPage < ads.length) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const startIndex = (page - 1) * adsPerPage;
  const endIndex = page * adsPerPage;
  const paginatedAds = ads.slice(startIndex, endIndex);

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate("/moderation")} />
      <div className={s.wrapper}>
        <main className={s.main}>
          <div className={s.main__container}>
            <div className={s.ad_cards}>
              {paginatedAds.map((ad) => (
                <div key={ad.ad_id} className={s.ad_card}>
                  <Link
                    to={adAboutPath.replace("{ad_id}", ad.ad_id)}
                    onClick={() => impactOccurred("light")}
                  >
                    <div className={s.ad_card__stats}>
                      <div className={s.stats__is_verified}>
                        {!ad.need_verification && ad.is_verified
                          ? "✅ Объявление проверено"
                          : "❌ Объявление не проверено"}
                      </div>
                      <div
                        className={s.stats__avg_rating}
                      >{`⭐️ Рейтинг | ${ad.avg_rating}/5`}</div>
                      <div
                        className={s.stats__views_count}
                      >{`👁‍🗨 Просмотры | ${ad.views_count} шт.`}</div>
                    </div>
                    <div className={s.ad_card__photo}>
                      <img
                        src={`${photosApiPath}/${ad.photos[0]}`}
                        alt={`preview for ad#${ad.ad_id}`}
                      />
                    </div>
                    {ad && formFields && (
                      <AdDetails
                        ad={ad}
                        adFormFields={
                          ad.operation_type
                            ? formFields[
                                `${ad.ad_category}_${ad.operation_type}`
                              ]
                            : formFields[ad.ad_category]
                        }
                      />
                    )}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </main>
        <footer className={s.footer}>
          <div className={s.footer__container}>
            <div className={s.pagination}>
              <button
                className="button"
                onClick={handlePreviousPage}
                disabled={page === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="button"
                onClick={handleNextPage}
                disabled={endIndex >= ads.length}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default UnverifiedAdsList;
