import React from "react";
import FieldContainer from "./FieldContainer";

const SelectOrTextareaField = ({ field_key, field_value, state, onChange }) => (
  <FieldContainer title={field_value.title}>
    <div className="select_or_textarea_field">
      <select
        name={field_key}
        value={state[field_key] || "default_option"}
        onChange={onChange}
      >
        <option value="default_option">Выберите</option>
        {field_value.values.map((value, index) => (
          <option key={index} value={index}>
            {value}
          </option>
        ))}
      </select>
      {field_value.other && (
        <textarea
          name={field_key + "_other"}
          onChange={onChange}
          placeholder="Введите текст"
          value={state[field_key + "_other"] || ""}
        />
      )}
    </div>
  </FieldContainer>
);

export default SelectOrTextareaField;
