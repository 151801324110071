import getLocalizedCreatedAt from "../utils/getLocalizedCreatedAt";
import s from "../styles/AdDetails.module.css";

const AdDetails = ({ ad, adFormFields }) => {
  // eslint-disable-next-line default-case
  switch (ad.ad_category) {
    case "APARTMENT":
      // eslint-disable-next-line default-case
      switch (ad.operation_type) {
        case "SALE":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>{ad.form_data.cost} ₽</div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__rooms_count}>
                {ad.form_data.rooms_count}-комнатная
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__floor_number}>
                {ad.form_data.floor_number}/{ad.form_data.floors_count} этаж
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
        case "RENT":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>
                {ad.form_data.cost} ₽{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["cost"]["title"][
                    "values"
                  ][ad.form_data.rent_period]
                }
              </div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__comission}>
                Комиссия:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["comission"][
                    "values"
                  ][ad.form_data.comission]
                }
              </div>
              <div className={s.ad_card__rooms_count}>
                {ad.form_data.rooms_count}-комнатная
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__floor_number}>
                {ad.form_data.floor_number}/{ad.form_data.floors_count} этаж
              </div>
              <div className={s.ad_card__housing_type}>
                Тип жилья:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["housing_type"][
                    "values"
                  ][ad.form_data.housing_type]
                }
              </div>
              <div className={s.ad_card__house_rules}>
                Правила проживания:{" "}
                {ad.form_data.house_rules
                  .map(
                    (val) =>
                      adFormFields["sections"]["common"]["fields"][
                        "house_rules"
                      ]["values"][val]
                  )
                  .join(", ")}
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
      }
      break;
    case "ROOM":
      // eslint-disable-next-line default-case
      switch (ad.operation_type) {
        case "SALE":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>{ad.form_data.cost} ₽</div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__room_square}>
                {ad.form_data.room_square} м<sup>2</sup>
              </div>
              <div className={s.ad_card__floor_number}>
                {ad.form_data.floor_number}/{ad.form_data.floors_count} этаж
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
        case "RENT":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>
                {ad.form_data.cost} ₽{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["cost"]["title"][
                    "values"
                  ][ad.form_data.rent_period]
                }
              </div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__comission}>
                Комиссия:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["comission"][
                    "values"
                  ][ad.form_data.comission]
                }
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__floor_number}>
                {ad.form_data.floor_number}/{ad.form_data.floors_count} этаж
              </div>
              <div className={s.ad_card__housing_type}>
                Тип жилья:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["housing_type"][
                    "values"
                  ][ad.form_data.housing_type]
                }
              </div>
              <div className={s.ad_card__house_rules}>
                Правила проживания:{" "}
                {ad.form_data.house_rules
                  .map(
                    (val) =>
                      adFormFields["sections"]["common"]["fields"][
                        "house_rules"
                      ]["values"][val]
                  )
                  .join(", ")}
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
      }
      break;
    case "HOUSE":
      // eslint-disable-next-line default-case
      switch (ad.operation_type) {
        case "SALE":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>{ad.form_data.cost} ₽</div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__square_house}>
                {ad.form_data.square_house} м<sup>2</sup>
              </div>
              <div className={s.ad_card__square_land}>
                {ad.form_data.square_land} м<sup>2</sup>
              </div>
              <div className={s.ad_card__floors_count}>
                {ad.form_data.floors_count} этажей
              </div>
              <div className={s.ad_card__material_of_wall}>
                Материал стен:{" "}
                {ad.form_data.material_of_wall
                  .map(
                    (val) =>
                      adFormFields["sections"]["common"]["fields"][
                        "material_of_wall"
                      ]["values"][val]
                  )
                  .join(", ")}
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
        case "RENT":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>
                {ad.form_data.cost} ₽{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["cost"]["title"][
                    "values"
                  ][ad.form_data.rent_period]
                }
              </div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__comission}>
                Комиссия:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["comission"][
                    "values"
                  ][ad.form_data.comission]
                }
              </div>
              <div className={s.ad_card__square_house}>
                {ad.form_data.square_house} м<sup>2</sup>
              </div>
              <div className={s.ad_card__square_land}>
                {ad.form_data.square_land} м<sup>2</sup>
              </div>
              <div className={s.ad_card__floors_count}>
                {ad.form_data.floors_count} этажей
              </div>
              <div className={s.ad_card__house_rules}>
                Правила проживания:{" "}
                {ad.form_data.house_rules
                  .map(
                    (val) =>
                      adFormFields["sections"]["common"]["fields"][
                        "house_rules"
                      ]["values"][val]
                  )
                  .join(", ")}
              </div>
              <div className={s.ad_card__material_of_wall}>
                Материал стен:{" "}
                {ad.form_data.material_of_wall
                  .map(
                    (val) =>
                      adFormFields["sections"]["common"]["fields"][
                        "material_of_wall"
                      ]["values"][val]
                  )
                  .join(", ")}
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
      }
      break;
    case "COMMERCIAL":
      // eslint-disable-next-line default-case
      switch (ad.operation_type) {
        case "SALE":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>{ad.form_data.cost} ₽</div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__object_type}>
                Вид объекта:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["object_type"][
                    "values"
                  ][ad.form_data.object_type]
                }
              </div>
              <div className={s.ad_card__building_type}>
                Тип здания:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["building_type"][
                    "values"
                  ][ad.form_data.building_type]
                }
              </div>
              <div className={s.ad_card__rooms_count}>
                {ad.form_data.rooms_count}-комнатная
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__floor_number}>
                {ad.form_data.floor_number}/{ad.form_data.floors_count} этаж
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
        case "RENT":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__rent_per_month}>
                {ad.form_data.rent_per_month} ₽
              </div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__rental_type}>
                Тип аренды:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["rental_type"][
                    "values"
                  ][ad.form_data.rental_type]
                }
              </div>
              <div className={s.ad_card__comission}>
                Комиссия:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["comission"][
                    "values"
                  ][ad.form_data.comission]
                }
              </div>
              <div className={s.ad_card__object_type}>
                Вид объекта:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["object_type"][
                    "values"
                  ][ad.form_data.object_type]
                }
              </div>
              <div className={s.ad_card__building_type}>
                Тип здания:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["building_type"][
                    "values"
                  ][ad.form_data.building_type]
                }
              </div>
              <div className={s.ad_card__rooms_count}>
                {ad.form_data.rooms_count}-комнатная
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__floor_number}>
                {ad.form_data.floor_number}/{ad.form_data.floors_count} этаж
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
      }
      break;
    case "GARAGE":
      // eslint-disable-next-line default-case
      switch (ad.operation_type) {
        case "SALE":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>{ad.form_data.cost} ₽</div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__object_type}>
                Вид объекта:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["object_type"][
                    "values"
                  ][ad.form_data.object_type]
                }
              </div>
              <div className={s.ad_card__garage_type}>
                Тип гаража/бокса:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["garage_type"][
                    "values"
                  ][ad.form_data.garage_type]
                }
              </div>
              <div className={s.ad_card__type_of_parking_space}>
                Тип машиноместа:{" "}
                {
                  adFormFields["sections"]["common"]["fields"][
                    "type_of_parking_space"
                  ]["values"][ad.form_data.type_of_parking_space]
                }
              </div>
              <div className={s.ad_card__security}>
                Охрана:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["security"][
                    "values"
                  ][ad.form_data.security]
                }
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
        case "RENT":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__rent_per_month}>
                {ad.form_data.rent_per_month} ₽
              </div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__rental_type}>
                Тип аренды:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["rental_type"][
                    "values"
                  ][ad.form_data.rental_type]
                }
              </div>
              <div className={s.ad_card__comission}>
                Комиссия:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["comission"][
                    "values"
                  ][ad.form_data.comission]
                }
              </div>
              <div className={s.ad_card__object_type}>
                Вид объекта:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["object_type"][
                    "values"
                  ][ad.form_data.object_type]
                }
              </div>
              <div className={s.ad_card__garage_type}>
                Тип гаража/бокса:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["garage_type"][
                    "values"
                  ][ad.form_data.garage_type]
                }
              </div>
              <div className={s.ad_card__type_of_parking_space}>
                Тип машиноместа:{" "}
                {
                  adFormFields["sections"]["common"]["fields"][
                    "type_of_parking_space"
                  ]["values"][ad.form_data.type_of_parking_space]
                }
              </div>
              <div className={s.ad_card__security}>
                Охрана:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["security"][
                    "values"
                  ][ad.form_data.security]
                }
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
      }
      break;
    case "LAND":
      // eslint-disable-next-line default-case
      switch (ad.operation_type) {
        case "SALE":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__cost}>{ad.form_data.cost} ₽</div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__land_category}>
                Категория земель:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["land_category"][
                    "values"
                  ][ad.form_data.land_category]
                }
              </div>
              <div className={s.ad_card__security}>
                Охрана:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["security"][
                    "values"
                  ][ad.form_data.security]
                }
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
        case "RENT":
          return (
            <div className={s.ad_card__details}>
              <div className={s.ad_card__rent_per_month}>
                {ad.form_data.rent_per_month} ₽
              </div>
              <div className={s.ad_card__name}>{ad.form_data.name}</div>
              <div className={s.ad_card__rental_type}>
                Тип аренды:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["rental_type"][
                    "values"
                  ][ad.form_data.rental_type]
                }
              </div>
              <div className={s.ad_card__comission}>
                Комиссия:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["comission"][
                    "values"
                  ][ad.form_data.comission]
                }
              </div>
              <div className={s.ad_card__land_category}>
                Категория земель:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["land_category"][
                    "values"
                  ][ad.form_data.land_category]
                }
              </div>
              <div className={s.ad_card__security}>
                Охрана:{" "}
                {
                  adFormFields["sections"]["common"]["fields"]["security"][
                    "values"
                  ][ad.form_data.security]
                }
              </div>
              <div className={s.ad_card__square_total}>
                {ad.form_data.square_total} м<sup>2</sup>
              </div>
              <div className={s.ad_card__address}>{ad.form_data.address}</div>
              <div className={s.ad_card__created_at}>
                {getLocalizedCreatedAt(ad)}
              </div>
            </div>
          );
      }
      break;
    case "SERVICE":
      return (
        <div className={s.ad_card__details}>
          <div className={s.ad_card__cost}>
            {ad.form_data.cost} ₽ за{" "}
            {
              adFormFields["sections"]["common"]["fields"]["cost_type"][
                "values"
              ][ad.form_data.cost_type]
            }
          </div>
          <div className={s.ad_card__name}>{ad.form_data.name}</div>
          <div className={s.ad_card__executor}>
            {
              adFormFields["sections"]["common"]["fields"]["executor"][
                "values"
              ][ad.form_data.executor]
            }
          </div>
          <div className={s.ad_card__address}>{ad.form_data.address}</div>
          <div className={s.ad_card__created_at}>
            {getLocalizedCreatedAt(ad)}
          </div>
        </div>
      );
  }
};

export default AdDetails;
