import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import MultiselectField from "../components/fields/MultiselectField";
import SelectField from "../components/fields/SelectField";
import FromToNumberField from "../components/fields/FromToNumberField";
import { Loader } from "../components/Loader";
import {
  BackButton,
  useHapticFeedback,
} from "@vkruglikov/react-telegram-web-app";
import s from "../styles/AdsFilter.module.css";

function AdsFilter() {
  const [searchParams] = useSearchParams();
  const adCategory = searchParams.get("ad_category");
  const categoryLvl0 = searchParams.get("category_lvl_0");
  const categoryLvl1 = searchParams.get("category_lvl_1");
  const categoryLvl2 = searchParams.get("category_lvl_2");
  const operationType = searchParams.get("operation_type");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [, notificationOccurred] = useHapticFeedback();

  window.Telegram.WebApp.enableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  let adsListPath = `/ads?ad_category=${adCategory}&category_lvl_0=${categoryLvl0}&category_lvl_1=${categoryLvl1}&category_lvl_2=${categoryLvl2}`;
  let getFieldsApiPath = `${BASE_URL}/api/form_fields/?ad_category=${adCategory}`;
  if (operationType) {
    adsListPath += `&operation_type=${operationType}`;
    getFieldsApiPath += `&operation_type=${operationType}`;
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true); // Начало загрузки
      const response = await axios.get(getFieldsApiPath, {
        headers: { "ngrok-skip-browser-warning": "69420" },
      });
      const data = response.data;
      setData(data);
      if (location.state) {
        setState(location.state.filters);
      } else {
        let initialState = {};
        for (let section of Object.values(data["sections"])) {
          for (let [field_key, field_value] of Object.entries(
            section["fields"]
          )) {
            if (typeof field_value === "object" && field_value !== null) {
              if (field_value["type"] === "MULTISELECT") {
                initialState[field_key] = [];
              } else if (field_value["type"] === "SELECT") {
                initialState[field_key] = null;
              } else {
                initialState[field_key] = { from: null, to: null };
              }
            }
          }
        }
        setState(initialState);
      }
      setIsLoading(false); // Конец загрузки
    }
    fetchData();
  }, [adCategory, getFieldsApiPath, location]);

  const onChange = (e) => {
    const { name, value, selectedOptions } = e.target;
    const [fieldKey, boundary] = name.split(":");

    if (selectedOptions) {
      if (e.target.multiple) {
        setState((prevState) => ({
          ...prevState,
          [name]: Array.from(selectedOptions, (option) => option.value),
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [name]: value === "default_option" ? null : value,
        }));
      }
    } else {
      if (boundary) {
        setState((prevState) => ({
          ...prevState,
          [fieldKey]: {
            ...prevState[fieldKey],
            [boundary]: value,
          },
        }));
      } else {
        setState((prevState) => ({ ...prevState, [name]: value }));
      }
    }
  };

  const renderFields = (section_key, section_data) => {
    let fieldCount = 0;

    const renderedFields = Object.entries(section_data.fields)
      .map(([field_key, field_value]) => {
        if (typeof field_value === "object" && field_value !== null) {
          fieldCount++;
          switch (field_value.type) {
            case "MULTISELECT":
              return (
                <MultiselectField
                  key={field_key}
                  field_key={field_key}
                  field_value={field_value}
                  state={state}
                  onChange={onChange}
                />
              );
            case "SELECT":
              return (
                <SelectField
                  key={field_key}
                  field_key={field_key}
                  field_value={field_value}
                  state={state}
                  onChange={onChange}
                />
              );
            case "INTEGER":
              return (
                <FromToNumberField
                  key={field_key}
                  field_key={field_key}
                  field_value={field_value}
                  state={state}
                  onChange={onChange}
                  isInteger={true}
                />
              );
            default:
              return (
                <FromToNumberField
                  key={field_key}
                  field_key={field_key}
                  field_value={field_value}
                  state={state}
                  onChange={onChange}
                  isInteger={true}
                />
              );
          }
        } else {
          return null;
        }
      })
      .filter(Boolean); // Remove null values from the array

    if (fieldCount > 0) {
      return (
        <div key={section_key} className={s.section}>
          <div className={s.section__name}>
            <span>{section_data.title}:</span>
          </div>
          <div className={s.section__fields}>{renderedFields}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    notificationOccurred("success");
    navigate(adsListPath, {
      state: { filters: state },
      replace: true,
    });
  };

  const handleReset = (e) => {
    e.preventDefault();
    notificationOccurred("success");
    navigate(adsListPath, {
      state: null,
      replace: true,
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.wrapper}>
        <main className={s.main}>
          <div className={s.main__container}>
            <div className={s.form}>
              {data?.title && (
                <div className={s.form__name}>
                  {data.title.replace("Анкета", "Фильтр")}
                </div>
              )}
              <div className={s.form__sections}>
                {data?.sections &&
                  Object.entries(data.sections).map(
                    ([section_key, section_data]) =>
                      renderFields(section_key, section_data)
                  )}
              </div>
            </div>
          </div>
        </main>
        <footer className={s.footer}>
          <div className={s.footer__container}>
            <div className={s.buttons_group}>
              <button className="button" onClick={handleSubmit}>
                Применить фильтры
              </button>
              <button className="button" onClick={handleReset}>
                Очистить фильтры
              </button>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default AdsFilter;
