import React from "react";
import s from "../../styles/ModalStyles.module.css";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

function RatingModal({
  isOpen,
  onClose,
  rating,
  setRating,
  review,
  setReview,
  handleRatingSubmit,
}) {
  const [impactOccurred] = useHapticFeedback();
  if (!isOpen) return null;

  return (
    <div className={s.modal}>
      <div className={s.modalContent}>
        <div className={s.title}>Оценить объявление</div>
        <label>
          Оценка:
          <select value={rating} onChange={(e) => setRating(e.target.value)}>
            <option value="default_option">Выберите</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
        </label>
        <label>
          Отзыв:
          <textarea
            onChange={(e) => setReview(e.target.value)}
            placeholder="Введите текст"
            value={review}
          />
        </label>
        <div className={s.buttons_group}>
          <button className="button" onClick={handleRatingSubmit}>
            Отправить
          </button>
          <button
            className="button"
            onClick={() => {
              impactOccurred("light");
              onClose();
            }}
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
}

export default RatingModal;
