import React, { useEffect } from "react";
import s from "../../styles/Search/MainAdCategorySelector.module.css";
import { Link, useNavigate } from "react-router-dom";
import {
  BackButton,
  useHapticFeedback,
  useCloudStorage,
} from "@vkruglikov/react-telegram-web-app";
import { useParams } from "react-router-dom";

const MainAdCategorySelector = () => {
  const { action } = useParams();
  const navigate = useNavigate();
  const [, , selectionChanged] = useHapticFeedback();
  const storage = useCloudStorage();

  window.Telegram.WebApp.disableClosingConfirmation();

  useEffect(() => {
    storage.setItem("action", action);
  }, [action, storage]);

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <div className={s.title}>Выберите категорию</div>
        <div className={s.buttons_group}>
          <Link
            to="/real_estate"
            className={s.link}
            onClick={() => selectionChanged()}
          >
            Недвижимость
          </Link>
          <Link
            to="/service"
            className={s.link}
            onClick={() => selectionChanged()}
          >
            Услуги
          </Link>
        </div>
      </div>
    </>
  );
};

export default MainAdCategorySelector;
