import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  BackButton,
  MainButton,
  useHapticFeedback,
  useThemeParams,
  useCloudStorage,
  useShowPopup,
} from "@vkruglikov/react-telegram-web-app";
import s from "../../styles/Registration/SetPhoneNumber.module.css";

const SetPhoneNumber = () => {
  const navigate = useNavigate();
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const [, themeParams] = useThemeParams();
  const storage = useCloudStorage();
  const showPopup = useShowPopup();
  const [phoneNumber, setPhoneNumber] = useState("");

  window.Telegram.WebApp.enableClosingConfirmation();

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
    return phoneRegex.test(number);
  };

  const cleanPhoneNumber = (number) => {
    return number.replace(/\D/g, "").replace(/^7/, "");
  };

  const handleNext = async () => {
    if (validatePhoneNumber(phoneNumber)) {
      impactOccurred("light");
      await storage.setItem("phoneNumber", cleanPhoneNumber(phoneNumber));
      navigate("/reg/set_city");
    } else {
      notificationOccurred("warning");
      await showPopup({
        message: "⚠️ Вы не ввели номер телефона или неверный формат.",
      });
    }
  };

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <p className={s.text}>
          ✏️ Введите номер телефона в формате <code>+7 (XXX) XXX-XX-XX</code>.
        </p>
        <InputMask
          mask="+7 (999) 999-99-99"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className={s.input}
          placeholder="+7 (___) ___-__-__"
          maskChar="_"
        />
      </div>
      {phoneNumber && (
        <MainButton
          text={"ДАЛЕЕ"}
          color={themeParams.hint_color}
          onClick={handleNext}
        />
      )}
    </>
  );
};

export default SetPhoneNumber;
