import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BackButton,
  MainButton,
  useHapticFeedback,
  useThemeParams,
  useCloudStorage,
} from "@vkruglikov/react-telegram-web-app";
import s from "../../styles/Search/RealEstateCategorySelector.module.css";

const RealEstateCategorySelector = () => {
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();
  const [impactOccurred, , selectionChanged] = useHapticFeedback();
  const [, themeParams] = useThemeParams();
  const storage = useCloudStorage();
  const [action, setAction] = useState(null);

  window.Telegram.WebApp.disableClosingConfirmation();

  const realEstateCategories = [
    { id: 0, name: "Квартира", key_name: "APARTMENT" },
    { id: 1, name: "Комната", key_name: "ROOM" },
    { id: 2, name: "Дом, дача, коттедж", key_name: "HOUSE" },
    { id: 3, name: "Коммерческая недвижимость", key_name: "COMMERCIAL" },
    { id: 4, name: "Гараж, машиноместо", key_name: "GARAGE" },
    { id: 5, name: "Земельный участок", key_name: "LAND" },
  ];

  const operations = [
    {
      id: 0,
      name: "Аренда",
      key_name: "RENT",
      actions: [
        {
          id: 0,
          name: action === "create" ? "Сдать" : "Сдают",
          roles: ["Собственник", "Посредник"],
        },
        {
          id: 1,
          name: action === "create" ? "Снять" : "Снимают",
          roles: ["Себе", "Клиенту"],
        },
      ],
    },
    {
      id: 1,
      name: "Продажа",
      key_name: "SALE",
      actions: [
        {
          id: 0,
          name: action === "create" ? "Продать" : "Продажа",
          roles: ["Собственник", "Посредник"],
        },
        {
          id: 1,
          name: action === "create" ? "Купить" : "Покупка",
          roles: ["Себе", "Клиенту"],
        },
      ],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setAction(await storage.getItem("action"));
    };
    fetchData();
  }, [storage]);

  const handleSubmit = async () => {
    impactOccurred("light");

    let navigateRootPath =
      action === "create" ? "/form?action=create&" : `/ads?`;

    const adCategory = realEstateCategories[selectedCategory].key_name;
    const operation_type = operations[selectedOperation].key_name;

    navigateRootPath += `ad_category=${adCategory}&category_lvl_0=${selectedOperation}&category_lvl_1=${selectedAction}&category_lvl_2=${selectedRole}&operation_type=${operation_type}`;
    navigate(navigateRootPath);
  };

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <div className={s.title}>Недвижимость</div>
        <div>
          <div className={s.sectionTitle}>Что Вас интересует?</div>
          <div className={s.buttonGroup}>
            {operations.map((op) => (
              <button
                key={op.id}
                onClick={() => {
                  selectionChanged();
                  setSelectedOperation(op.id);
                }}
                className={`${s.button} ${
                  selectedOperation === op.id ? s.selected : ""
                }`}
              >
                {op.name}
              </button>
            ))}
          </div>
        </div>
        {selectedOperation !== null && (
          <div>
            <div className={s.sectionTitle}>
              {action === "create"
                ? "Что Вы хотите сделать?"
                : "Выберите направление:"}
            </div>
            <div className={s.buttonGroup}>
              {operations[selectedOperation].actions.map((action) => (
                <button
                  key={action.id}
                  onClick={() => {
                    selectionChanged();
                    setSelectedAction(action.id);
                  }}
                  className={`${s.button} ${
                    selectedAction === action.id ? s.selected : ""
                  }`}
                >
                  {action.name}
                </button>
              ))}
            </div>
          </div>
        )}
        {selectedAction !== null && (
          <div>
            <div className={s.sectionTitle}>
              {action === "create"
                ? "Ваш статус?"
                : selectedOperation === 0
                ? selectedAction === 0
                  ? "Арендодатель"
                  : "Арендатор"
                : selectedAction === 0
                ? "Продавец"
                : "Покупатель"}
            </div>
            <div className={s.buttonGroup}>
              {operations[selectedOperation].actions[selectedAction].roles.map(
                (role, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      selectionChanged();
                      setSelectedRole(index);
                    }}
                    className={`${s.button} ${
                      selectedRole === index ? s.selected : ""
                    }`}
                  >
                    {role}
                  </button>
                )
              )}
            </div>
          </div>
        )}
        {selectedRole !== null && (
          <div>
            <div className={s.sectionTitle}>Какая недвижимость?</div>
            <div className={s.buttonGroup}>
              {realEstateCategories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => {
                    selectionChanged();
                    setSelectedCategory(category.id);
                  }}
                  className={`${s.button} ${
                    selectedCategory === category.id ? s.selected : ""
                  }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      {selectedCategory !== null && (
        <MainButton
          text={
            action === "create" ? "ПЕРЕЙТИ К СОЗДАНИЮ" : "ПЕРЕЙТИ К ПРОСМОТРУ"
          }
          color={themeParams.hint_color}
          onClick={handleSubmit}
        />
      )}
    </>
  );
};

export default RealEstateCategorySelector;
