import moment from "moment-timezone";

function toTimeZone(time, zone) {
  // Преобразуем время в нужный часовой пояс
  return moment.utc(time).tz(zone).toDate(); // Используем toDate для конвертации в объект Date
}

const getLocalizedCreatedAt = (ad) => {
  const date = ad.created_at; // Считаем, что ad.created_at это строка формата "YYYY-MM-DD HH:mm:ss"

  // Преобразуем в московское время
  const moscowTime = toTimeZone(date, "Europe/Moscow");

  // Используем toLocaleString для форматирования
  return moscowTime.toLocaleString("ru", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export default getLocalizedCreatedAt;
