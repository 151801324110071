import { useInitData } from "@vkruglikov/react-telegram-web-app";
import axios from "axios";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [hasProfile, setHasProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileChecked, setIsProfileChecked] = useState(false);
  const [, initData] = useInitData();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const usersApiPath = `${BASE_URL}/api/users`;

  const fetchProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const request = await axios.post(usersApiPath + "/check_profile", {
        init_data: initData,
      });
      setHasProfile(request.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsProfileChecked(true);
    }
  }, [initData, usersApiPath]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return (
    <ProfileContext.Provider
      value={{ hasProfile, isLoading, fetchProfile, isProfileChecked }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
