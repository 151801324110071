import React from "react";
import s from "../../styles/ModalStyles.module.css";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

function RejectModal({
  isOpen,
  onClose,
  rejectReason,
  setRejectReason,
  handleReject,
}) {
  const [impactOccurred] = useHapticFeedback();
  if (!isOpen) return null;

  return (
    <div className={s.modal}>
      <div className={s.modalContent}>
        <div className={s.title}>Причина отклонения</div>
        <textarea
          onChange={(e) => setRejectReason(e.target.value)}
          placeholder="Введите текст"
          value={rejectReason}
        />
        <div className={s.buttons_group}>
          <button className="button" onClick={handleReject}>
            Отклонить
          </button>
          <button
            className="button"
            onClick={() => {
              impactOccurred("light");
              onClose();
            }}
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
}

export default RejectModal;
