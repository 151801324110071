import { useNavigate } from "react-router-dom";
import s from "../styles/NoProfileWarning.module.css";

function NoProfileWarning() {
  const navigate = useNavigate();

  window.Telegram.WebApp.disableClosingConfirmation();

  return (
    <div className={s.container}>
      <div className={s.text}>Необходимо зарегистрироваться!</div>
      <button
        className="button"
        onClick={() => {
          navigate("/reg");
        }}
      >
        📝 ЗАРЕГИСТРИРОВАТЬСЯ
      </button>
    </div>
  );
}

export default NoProfileWarning;
