import React from "react";
import s from "../../styles/ModalStyles.module.css";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

function TariffPlansModal({ isOpen, onClose }) {
  const [impactOccurred] = useHapticFeedback();
  if (!isOpen) return null;

  return (
    <div className={s.modal}>
      <div className={s.modalContent}>
        <div className={s.title}>💰 Тарифы</div>
        <div className={s.text_tariff_plans}>
          <p>
            <b>Недвижимость</b>:
            <br />- 250 ₽ / 1 мес.
          </p>
          <p>
            <b>Услуги</b> и <b>Недвижимость</b> (посуточная аренда):
            <br />- 250 ₽ / 1 мес.
            <br />- 650 ₽ / 3 мес.
            <br />- 1200 ₽ / 6 мес.
            <br />- 2000 ₽ / 12 мес.
          </p>
          <p>🆓 Первый месяц бесплатно.</p>
        </div>
        <button
          className="button"
          onClick={() => {
            impactOccurred("light");
            onClose();
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
}

export default TariffPlansModal;
