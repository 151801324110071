import React from "react";
import s from "../../styles/ModalStyles.module.css";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

function ContactModal({ isOpen, onClose, ad }) {
  const [impactOccurred] = useHapticFeedback();
  if (!isOpen) return null;

  return (
    <div className={s.modal}>
      <div className={s.modalContent}>
        <div className={s.title}>Данные о владельце объявления</div>
        <div className={s.owner_details}>
          <p>
            <b>Username:</b> {ad.username ? "@" + ad.username : "-"}
          </p>
          <p>
            <b>Имя:</b> {ad.name}
          </p>
          <p>
            <b>Телефон:</b> +7{ad.phone_number}
          </p>
        </div>
        <button
          className="button"
          onClick={() => {
            impactOccurred("light");
            onClose();
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
}

export default ContactModal;
