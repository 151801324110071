import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BackButton,
  MainButton,
  useHapticFeedback,
  useThemeParams,
  useCloudStorage,
} from "@vkruglikov/react-telegram-web-app";
import s from "../../styles/Search/ServiceCategorySelector.module.css";

const serviceCategories = [
  {
    id: 0,
    name: "Спрос",
    subcategories: [
      {
        id: 0,
        name: "Оформление, Продажа/Аренда",
        services: [
          "Маклер",
          "Агент",
          "Наставник",
          "Юрист",
          "Ипотечный брокер",
          "Оценка",
          "Другое",
        ],
      },
      {
        id: 1,
        name: "Ремонт и отделка",
        services: [
          "Дизайнер интерьеров",
          "Перегородки",
          "Полы и напольные покрытия",
          "Штукатурные работы",
          "Шпаклевка",
          "Электрик",
          "Сантехник",
          "Вентиляция",
          "Плиточные работы",
          "Потолки",
          "Окна",
          "Мебель изготовление",
          "Другое",
        ],
      },
      {
        id: 2,
        name: "Строительство",
        services: [
          "Дома под ключ",
          "Бани, веранды, гаражи",
          "Проектирование и изыскания",
          "Дизайнер интерьеров",
          "Работа по дереву",
          "Снос и демонтаж",
          "Фундамент",
          "Бетонные работы",
          "Кладка блоков, кирпича",
          "Сварочные работы",
          "Кровельные работы",
          "Фасадные работы",
          "Окна",
          "Лестницы",
          "Алмазное сверление и резка",
          "Другое",
        ],
      },
    ],
  },
  {
    id: 1,
    name: "Предложение",
    subcategories: [
      {
        id: 0,
        name: "Оформление, Продажа/Аренда",
        services: [
          "Маклер",
          "Агент",
          "Наставник",
          "Юрист",
          "Ипотечный брокер",
          "Оценка",
          "Другое",
        ],
      },
      {
        id: 1,
        name: "Ремонт и отделка",
        services: [
          "Дизайнер интерьеров",
          "Перегородки",
          "Полы и напольные покрытия",
          "Штукатурные работы",
          "Шпаклевка",
          "Электрик",
          "Сантехник",
          "Вентиляция",
          "Плиточные работы",
          "Потолки",
          "Окна",
          "Мебель изготовление",
          "Другое",
        ],
      },
      {
        id: 2,
        name: "Строительство",
        services: [
          "Дома под ключ",
          "Бани, веранды, гаражи",
          "Проектирование и изыскания",
          "Дизайнер интерьеров",
          "Работа по дереву",
          "Снос и демонтаж",
          "Фундамент",
          "Бетонные работы",
          "Кладка блоков, кирпича",
          "Сварочные работы",
          "Кровельные работы",
          "Фасадные работы",
          "Окна",
          "Лестницы",
          "Алмазное сверление и резка",
          "Другое",
        ],
      },
    ],
  },
];

const ServiceCategorySelector = () => {
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedSphere, setSelectedSphere] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const navigate = useNavigate();
  const [impactOccurred, , selectionChanged] = useHapticFeedback();
  const [, themeParams] = useThemeParams();
  const storage = useCloudStorage();
  const [action, setAction] = useState(null);

  window.Telegram.WebApp.disableClosingConfirmation();

  useEffect(() => {
    const fetchData = async () => {
      setAction(await storage.getItem("action"));
    };
    fetchData();
  }, [storage]);

  const handleSubmit = async () => {
    impactOccurred("light");

    let navigateRootPath =
      action === "create" ? "/form?action=create&" : `/ads?`;

    navigateRootPath += `ad_category=SERVICE&category_lvl_0=${selectedMarket}&category_lvl_1=${selectedSphere}&category_lvl_2=${selectedService}`;
    navigate(navigateRootPath);
  };

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <div className={s.title}>Услуги</div>
        <div>
          <div className={s.sectionTitle}>Что Вас интересует?</div>
          <div className={s.buttonGroup}>
            {serviceCategories.map((market) => (
              <button
                key={market.id}
                onClick={() => {
                  selectionChanged();
                  setSelectedMarket(market.id);
                }}
                className={`${s.button} ${
                  selectedMarket === market.id ? s.selected : ""
                }`}
              >
                {market.name}
              </button>
            ))}
          </div>
        </div>
        {selectedMarket !== null && (
          <div>
            <div className={s.sectionTitle}>Ваш профиль?</div>
            <div className={s.buttonGroup}>
              {serviceCategories[selectedMarket].subcategories.map((sphere) => (
                <button
                  key={sphere.id}
                  onClick={() => {
                    selectionChanged();
                    setSelectedSphere(sphere.id);
                  }}
                  className={`${s.button} ${
                    selectedSphere === sphere.id ? s.selected : ""
                  }`}
                >
                  {sphere.name}
                </button>
              ))}
            </div>
          </div>
        )}
        {selectedSphere !== null && (
          <div>
            <div className={s.sectionTitle}>Что за услуга?</div>
            <div className={s.buttonGroup}>
              {serviceCategories[selectedMarket].subcategories[
                selectedSphere
              ].services.map((service, index) => (
                <button
                  key={index}
                  onClick={() => {
                    selectionChanged();
                    setSelectedService(index);
                  }}
                  className={`${s.button} ${
                    selectedService === index ? s.selected : ""
                  }`}
                >
                  {service}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      {selectedService !== null && (
        <MainButton
          text={
            action === "create" ? "ПЕРЕЙТИ К СОЗДАНИЮ" : "ПЕРЕЙТИ К ПРОСМОТРУ"
          }
          color={themeParams.hint_color}
          onClick={handleSubmit}
        />
      )}
    </>
  );
};

export default ServiceCategorySelector;
