import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useShowPopup, useHapticFeedback, useInitData } from "@vkruglikov/react-telegram-web-app";
import { QRCodeSVG } from 'qrcode.react';
import AdForm from "./pages/AdForm";
import AdsList from "./pages/AdsList";
import MyAdsList from "./pages/PersonalAccount/MyAdsList";
import AdsFilter from "./pages/AdsFilter";
import AdAbout from "./pages/AdAbout";
import MainAdCategorySelector from './pages/Search/MainAdCategorySelector';
import RealEstateCategorySelector from './pages/Search/RealEstateCategorySelector';
import ServiceCategorySelector from './pages/Search/ServiceCategorySelector';
import ImportantToKnow from './pages/Registration/ImportantToKnow';
import SetName from './pages/Registration/SetName';
import SetPhoneNumber from './pages/Registration/SetPhoneNumber';
import SetCity from './pages/Registration/SetCity';
import ChangePhoneNumber from './pages/Settings/ChangePhoneNumber';
import ChangeCity from './pages/Settings/ChangeCity';
import PaymentPage from './pages/PaymentPage';
import HomePage from './pages/HomePage';
import PersonalAccountPage from './pages/PersonalAccount/PersonalAccountPage';
import SettingsPage from './pages/Settings/SettingsPage';
import ModerationPage from './pages/Moderation/ModerationPage';
import UnverifiedAdsList from './pages/Moderation/UnverifiedAdsList';
import UnverifiedProfiles from './pages/Moderation/UnverifiedProfiles';
import NoProfileWarning from './pages/NoProfileWarning';
import { ProfileProvider } from './contexts/ProfileContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import InputSearchArea from './pages/Search/InputSearchArea';

function App() {
  const showPopup = useShowPopup();
  const [, notificationOccurred] = useHapticFeedback();
  const [initDataUnsafe] = useInitData();
  const [isInvalidVersion, setIsInvalidVersion] = useState(false);
  const [isWebPlatform, setIsWebPlatform] = useState(false);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      if (!window.Telegram.WebApp.isVersionAtLeast("6.9")) {
        notificationOccurred("error");
        if (window.Telegram.WebApp.isVersionAtLeast("6.2")) {
          showPopup({
            message: "Пожалуйста обновите Telegram до последней версии для того чтобы использовать это приложение."
          });
        } else {
          console.log("the version is not supported");
          setIsInvalidVersion(true);
        }
      }

      if (window.Telegram.WebApp.platform === "web") {
        setIsWebPlatform(true);
      }

      try {
        window.Telegram.WebApp.requestWriteAccess();
      } catch (e) {
        console.log(e);
      }

      window.Telegram.WebApp.expand();
    }
  }, [notificationOccurred, showPopup]);

  return (
    <>
      {isInvalidVersion && (
        <div>
          <h1>Извините, но ваша версия Telegram устарела!</h1>
          <p>Для использования этого приложения вам необходимо обновить Telegram.</p>
        </div>
      )}

      {isWebPlatform && initDataUnsafe.user.id !== Number(process.env.REACT_APP_ADMIN_ID) && (
        <div className="is_web_platform_container">
          <h2>Пожалуйста, используйте мобильную или десктоп версию Telegram!</h2>
          <p>Сканируйте QR-код, чтобы перейти в приложение через мобильную версию Telegram:</p>
          <QRCodeSVG value={process.env.REACT_APP_MINI_APP_URL} />
        </div>
      )}

      {((!isInvalidVersion && !isWebPlatform) || (!isInvalidVersion && isWebPlatform && initDataUnsafe.user.id === Number(process.env.REACT_APP_ADMIN_ID))) && (
        <ProfileProvider>
          <BrowserRouter>
            <div className="App">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/no_profile" element={<NoProfileWarning />} />
                <Route path="/reg" element={<ImportantToKnow />} />
                <Route path="/reg/set_name" element={<SetName />} />
                <Route path="/reg/set_phone_number" element={<SetPhoneNumber />} />
                <Route path="/reg/set_city" element={<SetCity />} />
                <Route path="/personal_account" element={<PersonalAccountPage />} />
                <Route path="/moderation" element={<ModerationPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/change_city" element={<ChangeCity />} />
                <Route path="/change_phone_number" element={<ChangePhoneNumber />} />
                <Route path="/input_search_area" element={<InputSearchArea />} />
                <Route path="/main_ad_category/:action" element={<MainAdCategorySelector />} />
                <Route path="/real_estate" element={<RealEstateCategorySelector />} />
                <Route path="/service" element={<ServiceCategorySelector />} />
                <Route path="/form" element={
                    <ProtectedRoute>
                      <AdForm />
                    </ProtectedRoute>
                } />
                <Route path="/ads" element={<AdsList />} />
                <Route path="/my_ads" element={<MyAdsList />} />
                <Route path="/unverified_ads" element={<UnverifiedAdsList />} />
                <Route path="/unverified_profiles" element={<UnverifiedProfiles />} />
                <Route path="/ads/filter" element={<AdsFilter />} />
                <Route path="/ads/:adId" element={<AdAbout />} />
                <Route path="/pay/:adId" element={<PaymentPage />} />
              </Routes>
            </div>
          </BrowserRouter>
        </ProfileProvider>
      )}
    </>
  );
}

export default App;
