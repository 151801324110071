import React, { useState } from "react";
import s from "../../styles/Search/InputSearchArea.module.css";
import { useNavigate } from "react-router-dom";
import {
  BackButton,
  useHapticFeedback,
  useCloudStorage,
} from "@vkruglikov/react-telegram-web-app";
import InputSearchAreaAddressField from "../../components/fields/InputSearchAreaAddressField";

const InputSearchArea = () => {
  const navigate = useNavigate();

  const storage = useCloudStorage();
  const [impactOccurred] = useHapticFeedback();

  const [searchBy, setSearchBy] = useState("");
  const [address, setAddress] = useState("");
  const [geoLat, setGeoLat] = useState(null);
  const [geoLon, setGeoLon] = useState(null);
  const [radius, setRadius] = useState("");
  const [city, setCity] = useState(null);
  const [region, setRegion] = useState(null);

  const handleChangeSearchBy = async (searchBy) => {
    impactOccurred("light");
    setSearchBy(searchBy);
    setAddress("");
    setGeoLat(null);
    setGeoLon(null);
    setRadius("");
    setCity(null);
    setRegion(null);
  };

  const handleSubmit = async () => {
    impactOccurred("light");
    await storage.setItem(
      "searchAreaData",
      JSON.stringify({
        searchBy,
        address,
        geoLat,
        geoLon,
        radius,
        city,
        region,
      })
    );
    navigate("/main_ad_category/show");
  };

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.container}>
        <div className={s.title}>Область поиска объявлений</div>
        <div className={s.subtitle}>
          Выберите
          <br />
          вариант поиска:
        </div>
        <div className={s.buttons_group}>
          <button
            className={`${s.button} ${searchBy === "region" ? s.selected : ""}`}
            onClick={() => {
              if (searchBy !== "region") handleChangeSearchBy("region");
            }}
          >
            Поиск по региону
          </button>
          <button
            className={`${s.button} ${searchBy === "city" ? s.selected : ""}`}
            onClick={() => {
              if (searchBy !== "city") handleChangeSearchBy("city");
            }}
          >
            Поиск по городу
          </button>
          <button
            className={`${s.button} ${
              searchBy === "address" ? s.selected : ""
            }`}
            onClick={() => {
              if (searchBy !== "address") handleChangeSearchBy("address");
            }}
          >
            Поиск по адресу
          </button>
        </div>
        {searchBy !== "" && (
          <div className={s.fields}>
            <InputSearchAreaAddressField
              field_key="address"
              field_value={
                searchBy === "address"
                  ? "Адрес"
                  : searchBy === "city"
                  ? "Город"
                  : "Регион"
              }
              placeholder={
                searchBy === "address"
                  ? "Введите адрес"
                  : searchBy === "city"
                  ? "Введите город"
                  : "Введите регион"
              }
              address={address}
              setAddress={setAddress}
              setGeoLat={setGeoLat}
              setGeoLon={setGeoLon}
              setCity={setCity}
              setRegion={setRegion}
              fieldContainerClassName={s.field_container}
              fieldNameClassName={s.field_name}
              addressFieldCLassName={s.input_field}
            />
            {searchBy === "address" && (
              <div className={s.field_container}>
                <div className={s.field_name}>
                  <b>
                    <i>Радиус (км):</i>
                  </b>
                </div>
                <div className={s.input_field}>
                  <div>
                    <input
                      type="number"
                      placeholder="Введите радиус"
                      value={radius}
                      onChange={(e) => setRadius(e.target.value)}
                      className={s.input}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {address &&
          ((searchBy === "address" && radius) ||
            searchBy === "region" ||
            searchBy === "city") && (
            <div className={s.submit_button_block}>
              <button onClick={handleSubmit} className="button">
                ДАЛЕЕ
              </button>
            </div>
          )}
      </div>
    </>
  );
};

export default InputSearchArea;
