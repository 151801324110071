/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import s from "../../styles/ModalStyles.module.css";
import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

function SupportModal({ isOpen, onClose }) {
  const WebApp = useWebApp();
  const [impactOccurred] = useHapticFeedback();
  if (!isOpen) return null;

  return (
    <div className={s.modal}>
      <div className={s.modalContent}>
        <div className={s.title}>👤 Помощь</div>
        <div className={s.text}>
          За помощью обращаться к{" "}
          <a
            href="#"
            onClick={() => WebApp.openLink("https://t.me/NARGENT_admin")}
            className={s.link}
          >
            @NARGENT_admin
          </a>
          .
        </div>
        <button
          className="button"
          onClick={() => {
            impactOccurred("light");
            onClose();
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
}

export default SupportModal;
