const calculateAvgRating = (reviews) => {
    let num = 0;
    if (reviews.length) {
      num =
        reviews.reduce(function (acc, review) {
          return acc + review.rating;
        }, 0) / reviews.length;
    }
    return (Math.round(num * 100) / 100).toFixed(2);
};

export default calculateAvgRating;