import React from "react";
import FieldContainer from "./FieldContainer";

const NumberField = ({
  field_key,
  field_value,
  state,
  onChange,
  isInteger,
}) => {
  let title = field_value.title;
  if (typeof title === "object") {
    if (state[title.depends] !== null) {
      title = title.default_value + " " + title.values[state[title.depends]];
    } else {
      title = title.default_value;
    }
  }
  return (
    <FieldContainer title={title}>
      <div className="number_field">
        <input
          name={field_key}
          value={state[field_key] || ""}
          onChange={onChange}
          type="number"
          placeholder="Введите число"
          min="0"
          step={isInteger ? "1" : "0.01"}
        />
      </div>
    </FieldContainer>
  );
};
export default NumberField;
