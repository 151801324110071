import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import s from "../styles/AdAbout.module.css";
import { Loader } from "../components/Loader";
import {
  useInitData,
  BackButton,
  useShowPopup,
  useHapticFeedback,
} from "@vkruglikov/react-telegram-web-app";
import ContactModal from "../components/Modals/ContactModal";
import RejectModal from "../components/Modals/RejectModal";
import BlockModal from "../components/Modals/BlockModal";
import DeleteModal from "../components/Modals/DeleteModal";
import RatingModal from "../components/Modals/RatingModal";
import calculateAvgRating from "../utils/calculateAvgRating";
import BalanceModal from "../components/Modals/BalanceModal";

function AdAbout() {
  const { adId } = useParams();

  const showPopup = useShowPopup();
  const [, InitData] = useInitData();
  const [impactOccurred, notificationOccurred] = useHapticFeedback();

  const [ad, setAd] = useState(null);
  const [adIsVerified, setAdIsVerified] = useState(null);
  const [adNeedVerification, setNeedVerification] = useState(null);
  const [hasRights, setHasRights] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [paymentIsEnable, setPaymentIsEnable] = useState(false);
  const [formFields, setFormFields] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [rating, setRating] = useState("default_option");
  const [review, setReview] = useState("");
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [avgRating, setAvgRating] = useState(0);
  const [viewsCount, setViewsCount] = useState(0);
  const [isReviewsVisible, setIsReviewsVisible] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [blockReason, setBlockReason] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const navigate = useNavigate();

  window.Telegram.WebApp.disableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const moderationApiPath = `${BASE_URL}/api/moderation`;
  const adsApiPath = `${BASE_URL}/api/ads`;
  const reviewsApiPath = `${BASE_URL}/api/reviews`;
  const photosApiPath = `${BASE_URL}/api/photos`;
  const adFormPath = `/form?action=edit&ad_id=${adId}`;
  const paymentPagePath = `/pay/${adId}`;

  const sliderSettings = {
    dots: true,
    arrows: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${adsApiPath}/get_ad_about_data`, {
        ad_id: adId,
        init_data: InitData,
      });
      const data = response.data;
      setAd(data.ad);
      setAdIsVerified(
        !data.ad.need_verification && data.ad.is_verified ? true : false
      );
      setNeedVerification(
        data.ad.need_verification && !data.ad.is_verified ? true : false
      );
      setFormFields(data.form_fields);
      setHasRights(data.has_rights);
      setIsOwner(data.is_owner);
      setPaymentIsEnable(data.payment_is_enable);
      setReviewsCount(data.ad.reviews_count);
      setAvgRating(data.ad.avg_rating);
      setViewsCount(data.ad.views_count);
    } catch (error) {
      console.log(error);
      notificationOccurred("error");
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    } finally {
      setIsLoading(false);
    }
  }, [adId, InitData, adsApiPath, notificationOccurred, showPopup]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchReviews = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(reviewsApiPath, {
        params: {
          ad_id: adId,
        },
      });
      const data = response.data;
      setReviews(data);
      setReviewsCount(data.length);
      setAvgRating(calculateAvgRating(data));
    } catch (error) {
      console.log(error);
      notificationOccurred("error");
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const generateContent = (formFields, adFormData) => {
    return Object.entries(formFields.sections).map(
      ([sectionKey, sectionData]) => {
        let sectionFields = [];
        const suffix = "_other";

        Object.entries(sectionData.fields).forEach(([fieldKey, fieldData]) => {
          let title, content;
          if (
            adFormData &&
            fieldKey !== "name" &&
            adFormData.hasOwnProperty(fieldKey)
          ) {
            if (typeof fieldData === "object") {
              title = fieldData.title;
              if (typeof title === "object") {
                title =
                  adFormData[title.depends] !== null
                    ? title.values[adFormData[title.depends]]
                    : title.default_value;
              }
              if (
                fieldData.type === "MULTISELECT" &&
                Array.isArray(adFormData[fieldKey])
              ) {
                content = adFormData[fieldKey]
                  .map((val) => fieldData.values[val])
                  .join(", ");
                if (
                  fieldData.other === true &&
                  adFormData.hasOwnProperty(fieldKey + suffix) &&
                  adFormData[fieldKey + suffix] &&
                  adFormData[fieldKey + suffix] !== null
                ) {
                  if (content) {
                    content += ` / ${adFormData[fieldKey + suffix]}`;
                  } else {
                    content = adFormData[fieldKey + suffix];
                  }
                }
              } else if (fieldData.type === "SELECT" && fieldData.values) {
                content = fieldData.values[adFormData[fieldKey]];
                if (
                  fieldData.other === true &&
                  adFormData.hasOwnProperty(fieldKey + suffix) &&
                  adFormData[fieldKey + suffix] &&
                  adFormData[fieldKey + suffix] !== null
                ) {
                  if (content) {
                    content += ` / ${adFormData[fieldKey + suffix]}`;
                  } else {
                    content = adFormData[fieldKey + suffix];
                  }
                }
              } else {
                content = adFormData[fieldKey];
              }
            } else {
              title = fieldData;
              content = adFormData[fieldKey];
            }
            if (title && content) {
              sectionFields.push(
                <div key={fieldKey} className={s.field}>
                  <div className={s.field__name}>{title}:</div>
                  <div className={s.field__content}>{content}</div>
                </div>
              );
            }
          }
        });
        if (sectionFields.length !== 0) {
          return (
            <div key={sectionKey} className={s.section}>
              <div className={s.section__fields}>{sectionFields}</div>
            </div>
          );
        } else {
          return null;
        }
      }
    );
  };

  const handleRatingSubmit = async () => {
    if (rating !== "default_option") {
      try {
        await axios.post(reviewsApiPath, {
          ad_id: adId,
          init_data: InitData,
          rating,
          review,
        });
        setIsRatingModalOpen(false);
        notificationOccurred("success");
        showPopup({
          message: "✅ Отзыв принят!",
        });
        fetchReviews();
      } catch (error) {
        console.log(error);
        notificationOccurred("error");
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      }
    } else {
      notificationOccurred("warning");
      showPopup({
        message: "⚠️ Вы должны выбрать оценку.",
      });
    }
  };

  const handleToggleReviews = () => {
    impactOccurred("light");
    if (isReviewsVisible) {
      setIsReviewsVisible(false);
    } else {
      fetchReviews();
      setIsReviewsVisible(true);
    }
  };

  const handleAccept = async () => {
    try {
      await axios.patch(moderationApiPath + "/ads/accept_ad", {
        ad_id: adId,
        owner_id: ad.user_id,
        init_data: InitData,
      });
      notificationOccurred("success");
      showPopup({
        message: "✅ Объявление принято.",
      });
      fetchData();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        showPopup({
          message: error.response.data.message,
        });
        if (error.response.data.redirect_to_profile) {
          navigate(`/unverified_profiles?profile_id=${ad.user_id}`);
        } else {
          navigate(`/unverified_ads`, { replace: true });
        }
      } else {
        console.log(error);
        notificationOccurred("error");
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      }
    }
  };

  const handleReject = async () => {
    if (rejectReason) {
      try {
        await axios.patch(moderationApiPath + "/ads/reject_ad", {
          ad_id: adId,
          owner_id: ad.user_id,
          init_data: InitData,
          reason: rejectReason,
        });
        setIsRejectModalOpen(false);
        notificationOccurred("success");
        showPopup({
          message: "✅ Объявление отклонено.",
        });
        fetchData();
      } catch (error) {
        if (error.response && error.response.status === 409) {
          showPopup({
            message: error.response.data.message,
          });
          if (error.response.data.redirect_to_profile) {
            navigate(`/unverified_profiles?profile_id=${ad.user_id}`);
          } else {
            navigate(`/unverified_ads`, { replace: true });
          }
        } else {
          console.log(error);
          notificationOccurred("error");
          showPopup({
            message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
          });
        }
      }
    } else {
      notificationOccurred("warning");
      showPopup({
        message: "⚠️ Вы должны ввести причину.",
      });
    }
  };

  const handleBlock = async () => {
    if (blockReason) {
      try {
        await axios.post(moderationApiPath + "/ads/delete_ad_and_block_owner", {
          ad_id: adId,
          owner_id: ad.user_id,
          init_data: InitData,
          reason: blockReason,
        });
        setIsBlockModalOpen(false);
        notificationOccurred("success");
        showPopup({
          message: "✅ Объявление заблокировано.",
        });
        fetchData();
      } catch (error) {
        if (error.response && error.response.status === 409) {
          showPopup({
            message: error.response.data.message,
          });
          navigate(`/unverified_ads`, { replace: true });
        } else {
          console.log(error);
          notificationOccurred("error");
          showPopup({
            message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
          });
        }
      }
    } else {
      notificationOccurred("warning");
      showPopup({
        message: "⚠️ Вы должны ввести причину.",
      });
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(adsApiPath + "/", {
        data: {
          ad_id: adId,
          owner_id: ad.user_id,
          init_data: InitData,
        },
      });
      setIsDeleteModalOpen(false);
      notificationOccurred("success");
      showPopup({
        message: "✅ Объявление удалено.",
      });
      // Отправляем пользователя обратно в список объявлений
      navigate(-1);
    } catch (error) {
      console.error(error);
      notificationOccurred("error");
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    }
  };

  const handleRenewAd = async () => {
    try {
      const response = await axios.patch(adsApiPath + "/renew_ad", {
        ad_id: adId,
        owner_id: ad.user_id,
        init_data: InitData,
      });
      notificationOccurred("success");
      showPopup({
        message: `✅ Ваше объявление #${adId} успешно продлено до ${response.data.active_until}!`,
      });
      fetchData();
    } catch (error) {
      console.error(error);
      notificationOccurred("error");
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate(-1)} />
      {ad && (
        <div className={s.wrapper}>
          <header className={s.header}>
            <div className={s.header__container}>
              <div className={s.ad__stats}>
                <div className={s.stats__is_verified}>
                  {adIsVerified
                    ? "✅ Объявление проверено"
                    : "❌ Объявление не проверено"}
                </div>
                <div
                  className={s.stats__avg_rating}
                >{`⭐️ Рейтинг | ${avgRating}/5`}</div>
                <div
                  className={s.stats__views_count}
                >{`👁‍🗨 Просмотры | ${viewsCount} шт.`}</div>
              </div>
            </div>
          </header>
          <main className={s.main}>
            <div className={s.main__container}>
              {ad.form_data && (
                <div className={s.ad_card__name}>
                  {ad.form_data.name}
                  <sup className={s.ad_card__id}>#{adId}</sup>
                </div>
              )}
              <div className={s.ad_photos_slider}>
                <div className={s.ad_photos_slider__container}>
                  {ad.photos &&
                    ad.photos.length > 0 &&
                    (ad.photos.length === 1 ? (
                      <div className={s.ad_photos_slider__photo}>
                        <img
                          src={`${photosApiPath}/${ad.photos[0]}`}
                          alt={`ph#0 for ad#${adId}`}
                        />
                      </div>
                    ) : (
                      <Slider {...sliderSettings}>
                        {ad.photos.map((photo, index) => (
                          <div key={index}>
                            <div className={s.ad_photos_slider__photo}>
                              <img
                                src={`${photosApiPath}/${photo}`}
                                alt={`ph#${index} for ad#${adId}`}
                              />
                            </div>
                          </div>
                        ))}
                      </Slider>
                    ))}
                </div>
              </div>
              <div className={s.ad_about}>
                {formFields.sections &&
                  ad.form_data &&
                  generateContent(formFields, ad.form_data)}
              </div>
            </div>
          </main>
          <footer className={s.footer}>
            <div className={s.footer__container}>
              <div className={s.buttons_group}>
                <button
                  className="button"
                  onClick={() => {
                    impactOccurred("light");
                    setIsContactModalOpen(true);
                  }}
                >
                  📞 Получить контакт
                </button>
                {!isOwner && (
                  <button
                    className="button"
                    onClick={() => {
                      impactOccurred("light");
                      setIsRatingModalOpen(true);
                    }}
                  >
                    📝 Оценить объявление
                  </button>
                )}
                {adNeedVerification && hasRights && (
                  <>
                    <div className={s.buttons__row}>
                      <button className="button" onClick={handleAccept}>
                        ✅ Принять
                      </button>
                      <button
                        className="button"
                        onClick={() => {
                          impactOccurred("light");
                          setIsRejectModalOpen(true);
                        }}
                      >
                        🚫 Отклонить
                      </button>
                    </div>
                    <button
                      className="button"
                      onClick={() => {
                        impactOccurred("light");
                        setIsBlockModalOpen(true);
                      }}
                    >
                      🅱️ Заблокировать
                    </button>
                  </>
                )}
                {isOwner && (
                  <>
                    {paymentIsEnable && (
                      <Link
                        to={paymentPagePath}
                        className="button"
                        onClick={() => impactOccurred("light")}
                      >
                        {!ad.is_active ? "💰 Оплатить" : "🔄 Продлить"}
                      </Link>
                    )}
                    {!ad.is_active && !paymentIsEnable && (
                      <button className="button" onClick={handleRenewAd}>
                        🔄 Продлить
                      </button>
                    )}
                    <Link
                      to={adFormPath}
                      className="button"
                      onClick={() => impactOccurred("light")}
                    >
                      ⚙️ Редактировать
                    </Link>
                    <button
                      className="button"
                      onClick={() => {
                        impactOccurred("light");
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      ❌ Удалить
                    </button>
                  </>
                )}
                <button className="button" onClick={handleToggleReviews}>
                  {isReviewsVisible
                    ? "Скрыть отзывы"
                    : `💬 Отзывы | ${reviewsCount} шт.`}
                </button>
              </div>
              <div className={s.reviews_section}>
                {isReviewsVisible && (
                  <div className={s.reviews}>
                    {reviews.length > 0 ? (
                      reviews.map((review, index) => (
                        <div key={index} className={s.review}>
                          <p>
                            <strong>Оценка:</strong> {review.rating}
                          </p>
                          <p>{review.text}</p>
                        </div>
                      ))
                    ) : (
                      <p>Отзывы отсутствуют</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </footer>
        </div>
      )}

      <RatingModal
        isOpen={isRatingModalOpen}
        onClose={() => setIsRatingModalOpen(false)}
        rating={rating}
        setRating={setRating}
        review={review}
        setReview={setReview}
        handleRatingSubmit={handleRatingSubmit}
      />

      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        ad={ad}
      />

      <RejectModal
        isOpen={adNeedVerification && hasRights && isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        rejectReason={rejectReason}
        setRejectReason={setRejectReason}
        handleReject={handleReject}
      />

      <BlockModal
        isOpen={adNeedVerification && hasRights && isBlockModalOpen}
        onClose={() => setIsBlockModalOpen(false)}
        blockReason={blockReason}
        setBlockReason={setBlockReason}
        handleBlock={handleBlock}
      />

      <DeleteModal
        message={"❔ Вы уверены, что хотите удалить это объявление?"}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        handleDelete={handleDelete}
      />

      <BalanceModal
        isOpen={isBalanceModalOpen}
        onClose={() => setIsBalanceModalOpen(false)}
        title={"Пополнение баланса ⤵️"}
      />
    </>
  );
}

export default AdAbout;
