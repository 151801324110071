import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import s from "../../styles/Moderation/UnverifiedProfiles.module.css";
import {
  BackButton,
  useInitData,
  useShowPopup,
  useHapticFeedback,
} from "@vkruglikov/react-telegram-web-app";
import { Loader } from "../../components/Loader";
import BlockModal from "../../components/Modals/BlockModal";
import RejectModal from "../../components/Modals/RejectModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function UnverifiedProfiles() {
  const [searchParams] = useSearchParams();
  const profileId = searchParams.get("profile_id");

  const [, InitData] = useInitData();
  const showPopup = useShowPopup();
  const [impactOccurred, notificationOccurred] = useHapticFeedback();

  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);
  const [page, setPage] = useState(1);
  const profilesPerPage = 5;
  const [isLoading, setIsLoading] = useState(false);

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [blockReason, setBlockReason] = useState("");
  const [currentProfileId, setCurrentProfileId] = useState(null);

  window.Telegram.WebApp.disableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const moderationApiPath = `${BASE_URL}/api/moderation`;

  const fetchData = useCallback(async () => {
    let config = {
      init_data: InitData,
    };
    if (profileId !== null) {
      config["profile_id"] = profileId;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        moderationApiPath + "/profiles/get_unverified",
        config
      );
      setProfiles(response.data);
    } catch (error) {
      console.error(error);
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    } finally {
      setIsLoading(false);
    }
  }, [InitData, moderationApiPath, profileId, showPopup]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAccept = async (profileId) => {
    try {
      await axios.patch(moderationApiPath + "/profiles/accept_profile", {
        owner_id: profileId,
        init_data: InitData,
      });
      notificationOccurred("success");
      showPopup({
        message: "✅ Профиль принят.",
      });
      if (profileId !== null) {
        navigate(`/unverified_profiles`, { replace: true });
      } else {
        fetchData();
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        showPopup({
          message: error.response.data.message,
        });
        if (profileId !== null) {
          navigate(`/unverified_profiles`, { replace: true });
        } else {
          fetchData();
        }
      } else {
        console.log(error);
        notificationOccurred("error");
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      }
    }
  };

  const handleReject = async () => {
    if (rejectReason) {
      try {
        await axios.patch(moderationApiPath + "/profiles/reject_profile", {
          owner_id: currentProfileId,
          reason: rejectReason,
          init_data: InitData,
        });
        setIsRejectModalOpen(false);
        notificationOccurred("success");
        showPopup({
          message: "✅ Профиль отклонен.",
        });
        if (profileId !== null) {
          navigate(`/unverified_profiles`, { replace: true });
        } else {
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          showPopup({
            message: error.response.data.message,
          });
          if (profileId !== null) {
            navigate(`/unverified_profiles`, { replace: true });
          } else {
            fetchData();
          }
        } else {
          console.log(error);
          notificationOccurred("error");
          showPopup({
            message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
          });
        }
      }
    } else {
      notificationOccurred("warning");
      showPopup({
        message: "⚠️ Вы должны ввести причину.",
      });
    }
  };

  const handleBlock = async () => {
    if (blockReason) {
      try {
        await axios.post(
          moderationApiPath + "/profiles/delete_profile_and_block_owner",
          {
            owner_id: currentProfileId,
            reason: blockReason,
            init_data: InitData,
          }
        );
        setIsBlockModalOpen(false);
        notificationOccurred("success");
        showPopup({
          message: "✅ Профиль заблокирован.",
        });
        if (profileId !== null) {
          navigate(`/unverified_profiles`, { replace: true });
        } else {
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          showPopup({
            message: error.response.data.message,
          });
          if (profileId !== null) {
            navigate(`/unverified_profiles`, { replace: true });
          } else {
            fetchData();
          }
        } else {
          console.log(error);
          notificationOccurred("error");
          showPopup({
            message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
          });
        }
      }
    } else {
      notificationOccurred("warning");
      showPopup({
        message: "⚠️ Вы должны ввести причину.",
      });
    }
  };

  const handleNextPage = () => {
    if (page * profilesPerPage < profiles.length) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const startIndex = (page - 1) * profilesPerPage;
  const endIndex = page * profilesPerPage;
  const paginatedProfiles = profiles.slice(startIndex, endIndex);

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate("/moderation")} />
      <div className={s.wrapper}>
        <main className={s.main}>
          <div className={s.main__container}>
            <div className={s.profile_cards}>
              {paginatedProfiles.map((profile) => (
                <div key={profile.user_id} className={s.profile_card}>
                  <div className={s.profile_details}>
                    <p>
                      <b>ID:</b> {profile.user_id}
                    </p>
                    <p>
                      <b>Username:</b>{" "}
                      {profile.username ? "@" + profile.username : "-"}
                    </p>
                    <p>
                      <b>Имя:</b> {profile.name}
                    </p>
                    <p>
                      <b>Телефон:</b> +7{profile.phone_number}
                    </p>
                  </div>
                  <div className={s.buttons_group}>
                    <button
                      className="button"
                      onClick={() => {
                        impactOccurred("light");
                        handleAccept(profile.user_id);
                      }}
                    >
                      ✅ Принять
                    </button>
                    <button
                      className="button"
                      onClick={() => {
                        impactOccurred("light");
                        setCurrentProfileId(profile.user_id);
                        setIsRejectModalOpen(true);
                      }}
                    >
                      🚫 Отклонить
                    </button>
                    <button
                      className="button"
                      onClick={() => {
                        impactOccurred("light");
                        setCurrentProfileId(profile.user_id);
                        setIsBlockModalOpen(true);
                      }}
                    >
                      🅱️ Заблокировать
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
        <footer className={s.footer}>
          <div className={s.footer__container}>
            <div className={s.pagination}>
              <button
                className="button"
                onClick={handlePreviousPage}
                disabled={page === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="button"
                onClick={handleNextPage}
                disabled={endIndex >= profiles.length}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </footer>
      </div>

      <RejectModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        rejectReason={rejectReason}
        setRejectReason={setRejectReason}
        handleReject={handleReject}
      />

      <BlockModal
        isOpen={isBlockModalOpen}
        onClose={() => setIsBlockModalOpen(false)}
        blockReason={blockReason}
        setBlockReason={setBlockReason}
        handleBlock={handleBlock}
      />
    </>
  );
}

export default UnverifiedProfiles;
