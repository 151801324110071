/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  MainButton,
  useHapticFeedback,
  useThemeParams,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import s from "../../styles/Registration/ImportantToKnow.module.css";

const ImportantToKnow = () => {
  const WebApp = useWebApp();
  const navigate = useNavigate();
  const [impactOccurred] = useHapticFeedback();
  const [, themeParams] = useThemeParams();

  window.Telegram.WebApp.disableClosingConfirmation();

  const handleNext = () => {
    impactOccurred("light");
    navigate("/reg/set_name");
  };

  return (
    <>
      <div className={s.container}>
        <p className={s.text}>
          1. Каждая анкета проходит жесткую модерацию, на соответствие
          реальности и профессиональной пригодности.
          <br />
          <br />
          2. Регистрируясь в сервисе, вы соглашаетесь с пользовательским
          соглашением и{" "}
          <a
            href="#"
            onClick={() =>
              WebApp.openLink(
                "https://docs.google.com/document/d/1O28Buo7lqgIgkLQoagSjqgKzWeR9_lWDPIPIRgy76Kk/edit?usp=sharing"
              )
            }
            className={s.link}
          >
            политикой конфиденциальности
          </a>
          .
        </p>
      </div>
      <MainButton
        text={"С СОГЛАШЕНИЕМ ОЗНАКОМЛЕН"}
        color={themeParams.hint_color}
        onClick={handleNext}
      />
    </>
  );
};

export default ImportantToKnow;
