import React, { useState, useEffect, useRef } from "react";
import FieldContainer from "./FieldContainer";
import axios from "axios";

const InputSearchAreaAddressField = ({
  field_key,
  field_value,
  placeholder,
  address,
  setAddress,
  setGeoLat,
  setGeoLon,
  setCity,
  setRegion,
  fieldContainerClassName,
  fieldNameClassName,
  addressFieldCLassName,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const wrapperRef = useRef(null); // Используем useRef для отслеживания кликов вне компонента

  const fetchSuggestions = async (query) => {
    try {
      const response = await axios.post(
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
        { query },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Token ${process.env.REACT_APP_DADATA_API_KEY}`,
          },
        }
      );
      setSuggestions(response.data.suggestions || []);
      setHasError(false);
    } catch (error) {
      console.error("Ошибка при получении подсказок:", error);
      setHasError(true);
      setGeoLat(null);
      setGeoLon(null);
      setCity(null);
      setRegion(null);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    setAddress(inputValue);

    setGeoLat(null);
    setGeoLon(null);
    setCity(null);
    setRegion(null);

    if (!hasError && inputValue) {
      fetchSuggestions(inputValue);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSuggestions([]);
    setAddress(suggestion.value);
    setGeoLat(suggestion.data.geo_lat);
    setGeoLon(suggestion.data.geo_lon);
    setCity(suggestion.data.city);
    setRegion(suggestion.data.region);
  };

  // Добавляем useEffect для управления событиями клика вне компонента
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSuggestions([]); // Скрываем подсказки
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <FieldContainer
      title={field_value}
      fieldContainerClassName={fieldContainerClassName}
      fieldNameClassName={fieldNameClassName}
    >
      <div className={addressFieldCLassName} ref={wrapperRef}>
        <div>
          <input
            type="text"
            name={field_key}
            value={address}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        </div>
        {!hasError && suggestions.length > 0 && (
          <div className="suggestions">
            {suggestions.map((suggestion) => (
              <button
                key={suggestion.value}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestion"
              >
                <span>{suggestion.value}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </FieldContainer>
  );
};

export default InputSearchAreaAddressField;
