import React from "react";
import s from "../../styles/ModalStyles.module.css";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

function BlockModal({
  isOpen,
  onClose,
  blockReason,
  setBlockReason,
  handleBlock,
}) {
  const [impactOccurred] = useHapticFeedback();
  if (!isOpen) return null;

  return (
    <div className={s.modal}>
      <div className={s.modalContent}>
        <div className={s.title}>Причина блокировки</div>
        <textarea
          onChange={(e) => setBlockReason(e.target.value)}
          placeholder="Введите текст"
          value={blockReason}
        />
        <div className={s.buttons_group}>
          <button className="button" onClick={handleBlock}>
            Заблокировать
          </button>
          <button
            className="button"
            onClick={() => {
              impactOccurred("light");
              onClose();
            }}
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
}

export default BlockModal;
