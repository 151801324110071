import s from "../styles/AdForm.module.css";
import { useState, useEffect, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import MultiselectOrTextareaField from "../components/fields/MultiselectOrTextareaField";
import SelectOrTextareaField from "../components/fields/SelectOrTextareaField";
import NumberField from "../components/fields/NumberField";
import TextareaField from "../components/fields/TextareaField";
import { Loader } from "../components/Loader";
import {
  BackButton,
  MainButton,
  useHapticFeedback,
  useInitData,
  useShowPopup,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import initializeState from "../utils/initializeState";
import areAllFieldsFilled from "../utils/areAllFieldsFilled";
import AddressField from "../components/fields/AddressField";

function AdForm() {
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");
  const adId = searchParams.get("ad_id");
  const adCategory = searchParams.get("ad_category");
  const categoryLvl0 = searchParams.get("category_lvl_0");
  const categoryLvl1 = searchParams.get("category_lvl_1");
  const categoryLvl2 = searchParams.get("category_lvl_2");
  const operationType = searchParams.get("operation_type");

  const WebApp = useWebApp();
  const [, notificationOccurred] = useHapticFeedback();
  const showPopup = useShowPopup();
  const [, InitData] = useInitData();

  const [state, setState] = useState({});
  const [formFields, setFormFields] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [geoLat, setGeoLat] = useState(null);
  const [geoLon, setGeoLon] = useState(null);
  const [city, setCity] = useState(null);
  const [region, setRegion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  window.Telegram.WebApp.enableClosingConfirmation();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const adAboutPath = "/ads/{adId}";
  const adsApiPath = `${BASE_URL}/api/ads`;
  const photosApiPath = `${BASE_URL}/api/photos`;
  const formFieldsApiPath =
    `${BASE_URL}/api/form_fields/?ad_category=${adCategory}` +
    (operationType ? `&operation_type=${operationType}` : "");

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        if (action === "create") {
          const response = await axios.get(formFieldsApiPath);
          setFormFields(response.data);
          setState(initializeState(response.data));
        } else {
          const response = await axios.get(`${adsApiPath}/${adId}`);
          setFormFields(response.data.form_fields);
          setState(response.data.ad.form_data);
          const photos = response.data.ad.photos || [];
          if (photos.length > 0) {
            try {
              const photoRequests = photos.map((photoName) =>
                axios.get(`${photosApiPath}/${photoName}`, {
                  responseType: "blob",
                })
              );
              // Выполняем все запросы параллельно
              const photoResponses = await axios.all(photoRequests);
              // Обрабатываем успешные ответы
              const existingFiles = photoResponses.map((response, index) => ({
                file: new File([response.data], photos[index], {
                  type: response.data.type,
                }),
                id: Math.random().toString(36).substring(2, 9),
                previewUrl: URL.createObjectURL(response.data),
              }));
              setSelectedFiles(existingFiles);
            } catch (error) {
              console.error(error);
              showPopup({
                message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
              });
            }
          } else {
            console.warn("No photos to fetch");
          }
          setGeoLat(response.data.ad.geo_lat);
          setGeoLon(response.data.ad.geo_lon);
          setCity(response.data.ad.city);
          setRegion(response.data.ad.region);
        }
      } catch (error) {
        console.error(error);
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [
    action,
    adCategory,
    adId,
    adsApiPath,
    formFieldsApiPath,
    photosApiPath,
    showPopup,
  ]);

  const onSendData = useCallback(async () => {
    // Проверка перед отправкой данных
    if (!areAllFieldsFilled(formFields.sections, state, selectedFiles)) {
      notificationOccurred("warning");
      showPopup({
        message:
          "⚠️ Должны быть заполнены все поля и добавлено хотя бы 1 фото.",
      });
      return; // Выход из функции, если проверка не пройдена
    }

    const formData = new FormData();
    // Добавляем общие данные
    formData.append("init_data", InitData);
    formData.append("form_data", JSON.stringify(state));
    selectedFiles.forEach((fileObj) => {
      formData.append("photos", fileObj.file); // Отправляем только файл
    });

    // Добавляем координаты и город, если они есть
    if (geoLat && geoLon && city && region) {
      formData.append("geo_lat", geoLat);
      formData.append("geo_lon", geoLon);
      formData.append("city", city);
      formData.append("region", region);
    }

    // Добавляем данные исходя из значения action
    if (action === "create") {
      formData.append("ad_category", adCategory);
      formData.append("category_lvl_0", categoryLvl0);
      formData.append("category_lvl_1", categoryLvl1);
      formData.append("category_lvl_2", categoryLvl2);
      if (operationType) {
        formData.append("operation_type", operationType);
      }
    } else {
      formData.append("ad_id", adId);
    }

    try {
      setIsLoading(true);
      if (action === "create") {
        const response = await axios.post(adsApiPath + "/", formData);
        setIsLoading(false);
        notificationOccurred("success");
        await showPopup({
          message: response.data.message,
        });
        // Отправляем пользователя на страницу с созданным объявлением и стираем информацию откуда пришли
        navigate(adAboutPath.replace("{adId}", response.data.ad_id), {
          replace: true,
        });
      } else {
        const response = await axios.put(adsApiPath + "/", formData);
        setIsLoading(false);
        notificationOccurred("success");
        await showPopup({
          message: response.data,
        });
        // Отправляем пользователя обратно в его объявление
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
      notificationOccurred("error");
      showPopup({
        message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    formFields,
    state,
    selectedFiles,
    InitData,
    geoLat,
    geoLon,
    city,
    region,
    action,
    notificationOccurred,
    showPopup,
    adCategory,
    categoryLvl0,
    categoryLvl1,
    categoryLvl2,
    operationType,
    adId,
    adsApiPath,
    navigate,
  ]);

  useEffect(() => {
    WebApp.onEvent("mainButtonClicked", onSendData);
    return () => {
      selectedFiles.forEach((fileObj) =>
        URL.revokeObjectURL(fileObj.previewUrl)
      );
      WebApp.offEvent("mainButtonClicked", onSendData);
    };
  }, [WebApp, onSendData, selectedFiles]);

  const onChange = (e) => {
    const { name, value, selectedOptions } = e.target;
    if (selectedOptions) {
      if (e.target.multiple) {
        // Для multiselect
        setState((prevState) => ({
          ...prevState,
          [name]: Array.from(selectedOptions, (option) => option.value),
        }));
      } else {
        // Для select
        setState((prevState) => ({
          ...prevState,
          [name]: value === "default_option" ? null : value,
        }));
      }
    } else {
      // Для input и textarea
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const onFilesChange = async (event) => {
    const files = Array.from(event.target.files);

    // Check the number of files
    if (files.length + selectedFiles.length > 10) {
      showPopup({
        message: "Вы можете загрузить не более 10 фотографий.",
      });
      return;
    }

    const validFiles = files.map((file) => ({
      file,
      id: Math.random().toString(36).substring(2, 9),
      previewUrl: URL.createObjectURL(file),
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const removeFile = (fileId) => {
    setSelectedFiles((prevFiles) => {
      const fileToRemove = prevFiles.find((file) => file.id === fileId);
      if (fileToRemove) {
        URL.revokeObjectURL(fileToRemove.previewUrl);
      }
      return prevFiles.filter((file) => file.id !== fileId);
    });
  };

  const renderFields = (section_key, section_data) => {
    const GARAGE = "0";
    const GARAGE_BOX = "1";
    const PARKING_SPACE = "2";

    return (
      <div key={section_key} className={s.section}>
        <div className={s.section__name}>
          <span>{section_data.title}:</span>
        </div>
        <div className={s.section__fields}>
          {Object.entries(section_data.fields).map(
            ([field_key, field_value]) => {
              // Проверяем, если это поле "address", то используем AddressField
              if (field_key === "address") {
                return (
                  <AddressField
                    key={field_key}
                    field_key={field_key}
                    field_value={field_value}
                    state={state}
                    setState={setState}
                    setGeoLat={setGeoLat}
                    setGeoLon={setGeoLon}
                    setCity={setCity}
                    setRegion={setRegion}
                  />
                );
              }

              // Логика для отображения дополнительных полей
              if (
                field_key === "garage_type" ||
                field_key === "type_of_parking_space"
              ) {
                const objectType = state.object_type;

                if (
                  field_key === "garage_type" &&
                  (objectType === GARAGE || objectType === GARAGE_BOX)
                ) {
                  return (
                    <SelectOrTextareaField
                      key={field_key}
                      field_key={field_key}
                      field_value={field_value}
                      state={state}
                      onChange={onChange}
                    />
                  );
                }

                if (
                  field_key === "type_of_parking_space" &&
                  objectType === PARKING_SPACE
                ) {
                  return (
                    <SelectOrTextareaField
                      key={field_key}
                      field_key={field_key}
                      field_value={field_value}
                      state={state}
                      onChange={onChange}
                    />
                  );
                }

                return null;
              }

              if (typeof field_value === "object" && field_value !== null) {
                switch (field_value.type) {
                  case "MULTISELECT":
                    return (
                      <MultiselectOrTextareaField
                        key={field_key}
                        field_key={field_key}
                        field_value={field_value}
                        state={state}
                        onChange={onChange}
                      />
                    );
                  case "SELECT":
                    return (
                      <SelectOrTextareaField
                        key={field_key}
                        field_key={field_key}
                        field_value={field_value}
                        state={state}
                        onChange={onChange}
                      />
                    );
                  case "INTEGER":
                    return (
                      <NumberField
                        key={field_key}
                        field_key={field_key}
                        field_value={field_value}
                        state={state}
                        onChange={onChange}
                        isInteger={true}
                      />
                    );
                  default:
                    return (
                      <NumberField
                        key={field_key}
                        field_key={field_key}
                        field_value={field_value}
                        state={state}
                        onChange={onChange}
                        isInteger={false}
                      />
                    );
                }
              } else {
                return (
                  <TextareaField
                    key={field_key}
                    field_key={field_key}
                    field_value={field_value}
                    state={state}
                    onChange={onChange}
                  />
                );
              }
            }
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.form}>
        {formFields?.title && (
          <div className={s.form__name}>{formFields.title}</div>
        )}
        <div className={s.form__sections}>
          {formFields?.sections &&
            Object.entries(formFields.sections).map(
              ([section_key, section_data]) =>
                renderFields(section_key, section_data)
            )}
        </div>
        <div className={s.images}>
          <div className={s.images__name}>
            <b>
              <i>Изображения:</i>
            </b>
          </div>
          <div className={s.images_container}>
            <div className={s.input_image}>
              <input
                type="file"
                // name="file[]"
                multiple
                // accept="image/*"
                onChange={onFilesChange}
                id="fileInput"
              />
              <span
                onClick={() => document.getElementById("fileInput").click()}
              >
                Выберите файл
              </span>
            </div>
            <div className={s.inputed_images}>
              {selectedFiles.map((fileObj) => (
                <div className={s.inputed_image} key={fileObj.id}>
                  <span className={s.inputed_image__name}>
                    <b>Название:</b> {fileObj.file.name}
                  </span>
                  <div className={s.inputed_image__image}>
                    <img src={fileObj.previewUrl} alt="Preview" />
                  </div>
                  <button type="button" onClick={() => removeFile(fileObj.id)}>
                    <FontAwesomeIcon icon={faTrash} /> Удалить
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <MainButton
          text={
            action === "create" ? "ОТПРАВИТЬ ДАННЫЕ" : "СОХРАНИТЬ ИЗМЕНЕНИЯ"
          }
          color="#28a745"
        />
      </div>
    </>
  );
}

export default AdForm;
