import React from "react";
import FieldContainer from "./FieldContainer";

const FromToNumberField = ({
  field_key,
  field_value,
  state,
  onChange,
  isInteger,
}) => {
  let title = field_value.title;
  if (typeof title === "object") {
    if (state[title.depends] !== null) {
      title = title.values[state[title.depends]];
    } else {
      title = title.default_value;
    }
  }
  return (
    <FieldContainer title={title}>
      <div className="from_to_number_field">
        <label htmlFor={`${field_key}:from`}>От:</label>
        <input
          name={`${field_key}:from`}
          value={state[field_key]?.from || ""}
          onChange={onChange}
          type="number"
          placeholder="Минимальное значение"
          min="0"
          step={isInteger ? "1" : "0.01"}
        />
        <label htmlFor={`${field_key}:to`}>До:</label>
        <input
          name={`${field_key}:to`}
          value={state[field_key]?.to || ""}
          onChange={onChange}
          type="number"
          placeholder="Максимальное значение"
          min="0"
          step={isInteger ? "1" : "0.01"}
        />
      </div>
    </FieldContainer>
  );
};

export default FromToNumberField;
