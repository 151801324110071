import React from "react";
import s from "../../styles/ModalStyles.module.css";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

function DeleteModal({ isOpen, onClose, handleDelete, message }) {
  const [impactOccurred] = useHapticFeedback();
  if (!isOpen) return null;

  return (
    <div className={s.modal}>
      <div className={s.modalContent}>
        <div className={s.message}>{message}</div>
        <div className={s.buttons_group}>
          <button className="button" onClick={handleDelete}>
            Да, удалить
          </button>
          <button
            className="button"
            onClick={() => {
              impactOccurred("light");
              onClose();
            }}
          >
            Отмена
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
